import React, { useState, useEffect } from "react";
import axios from "axios";
import { Input, Button, List, Avatar, Card, Row, Col } from "antd";
import {
  UserOutlined,
  RobotOutlined,
  BulbOutlined,
  BookOutlined,
  MessageOutlined,
  ReadOutlined,
} from "@ant-design/icons";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const { TextArea } = Input;

const AskAI = ({ customer_ObjectId }) => {
  const [messages, setMessages] = useState([]);
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);

  // Load messages from localStorage only if messages are empty
  useEffect(() => {
    if (messages.length === 0) {
      const storedMessages = JSON.parse(localStorage.getItem("conversationMessages"));
      if (storedMessages) {
        setMessages(storedMessages);
      }
    }
  }, [messages]);

// Save messages to localStorage whenever they change, if they are not empty
useEffect(() => {
  if (messages.length > 0) {
    localStorage.setItem("conversationMessages", JSON.stringify(messages));
  }
}, [messages]);

  const predefinedQuestions = [
    { question: "What tactics have been effective or ineffective for our competitors?", icon: <BulbOutlined /> },
    { question: "Which webinar topics are most popular among competitors' audiences?", icon: <MessageOutlined /> },
    { question: "What are the latest news?", icon: <ReadOutlined /> },
    { question: "What functionalities do different companies offer?", icon: <BookOutlined /> },
  ];

  const handleAskAI = async () => {
    if (!question) return;

    const instruction = 
      "Please respond in HTML format, using the following guidelines:\n" +
      "- Use <h1>, <h2>, and <h3> for main headings and section titles, styled with colors like #4A90E2.\n" +
      "- Use <p> for paragraphs with line-height 1.6 for readability.\n" +
      "- Highlight key terms with <b> for bold text, <i> for italics, and <span style='color: [color]'> for emphasis.\n" +
      "- Structure lists using <ul> and <li> with bullet points for key insights.\n" +
      "- Include <div> elements with background colors to create callout boxes or highlighted sections.\n" +
      "- Display tables with headers and data rows using <table>, <tr>, and <td> tags.\n" +
      "- Do not include any backticks (`" + "`" + "`), 'html', or code block markers in the response.\n" +
      "- Avoid any citations, metadata markers, or reference tags in the response.\n" +
      "- Only return clean HTML content with no extra commentary or concluding statements.\n" +
      "- Close all HTML tags correctly to prevent formatting issues.\n";
    const userMessage = { role: "user", content: question + instruction };
    setMessages([...messages, { role: "user", content: question }]);
    setQuestion("");
    setLoading(true);

    try {
      const res = await axios.post(`${BASE_URL}/ask`, {
        messages: [...messages, userMessage],
        customer_ObjectId,
      });
      const aiMessage = { role: "assistant", content: res.data.content };
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", content: "Error querying AI. Please try again later." }
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handlePredefinedQuestion = async (predefinedQuestion) => {
    const instruction = `
      Please respond in HTML format, using tags such as <b> for bold, <i> for italics, and <span style='color: [color]'> for color emphasis.
      Exclude any source citations, metadata, or commentary such as "This response highlights..."
      Only provide the response content directly, starting and ending with the relevant HTML tags.
    `;
    const userMessage = { role: "user", content: predefinedQuestion + instruction };
    setMessages([...messages, { role: "user", content: predefinedQuestion }]);
    setLoading(true);

    try {
      const res = await axios.post(`${BASE_URL}/ask`, {
        messages: [...messages, userMessage],
        customer_ObjectId,
      });
      const aiMessage = { role: "assistant", content: res.data.content };
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", content: "Error querying AI. Please try again later." }
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card style={{ minHeight: "400px" }}>
      <List
        itemLayout="horizontal"
        dataSource={messages}
        renderItem={(message) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={message.role === "user" ? <UserOutlined /> : <RobotOutlined />} />}
              title={message.role === "user" ? "You" : "AI"}
              description={
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    color: "black",
                  }}
                  dangerouslySetInnerHTML={{ __html: message.content }}
                />
              }
            />
          </List.Item>
        )}
        locale={{
          emptyText: loading ? null : "No conversation data available",
        }}
      />
      <div style={{ marginBottom: "16px" }}>
        <Row gutter={[16, 16]}>
          {predefinedQuestions.map((item, index) => (
            <Col key={index} span={6}>
              <Card hoverable onClick={() => handlePredefinedQuestion(item.question)}>
                <div style={{ textAlign: "center", padding: "16px" }}>{item.icon}</div>
                <Card.Meta description={item.question} />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextArea
          rows={4}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Ask a question..."
          style={{ marginRight: "16px" }}
        />
        <Button type="primary" onClick={handleAskAI} loading={loading}>
          Ask
        </Button>
      </div>
    </Card>
  );
};

export default AskAI;