import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Table,
  Button,
  Modal,
  Spin,
  message,
  Pagination,
  Select,
  Switch,
} from "antd";
import * as XLSX from "xlsx";
import {
  FileOutlined,
  TeamOutlined,
  BarChartOutlined,
  CalendarOutlined,
  VideoCameraOutlined,
  SmileOutlined,
  PieChartOutlined,
  LineChartOutlined,
  DollarOutlined,
  FundOutlined,
  RobotOutlined,
  DashboardOutlined,
  SolutionOutlined,
  LinkedinOutlined,
  UserOutlined,
  ZoomInOutlined,
  MinusCircleOutlined,
  RiseOutlined,
  FallOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import axios from "axios";
import AskAI from "./AskAI";
// import DashboardCharts from "./DashboardCharts";
import logo from "../battle-cards-2.webp";
import "../styles.css";
import { Carousel, Row, Col, Card, DatePicker } from "antd";
import * as d3 from "d3";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
// import linkedinData from '../linkedin_data.json';
import { useNavigate, useParams } from "react-router-dom"; // Import useParams
import moment from 'moment';
import DashboardSummary from './DashboardSummary';
import PriceChanges from './PriceChanges';
import Associations from './Associations';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const { Option } = Select;
const { RangePicker } = DatePicker;


const isLightColor = (color) => {
  if (!color) return true;
  const hex = color.replace("#", "");
  if (hex.length !== 6) return true;
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  return hsp > 127.5;
};

const generateSubCardColor = (index) => {
  const colors = [
    "#ffcccc",
    "#ccffcc",
    "#ccccff",
    "#ffffcc",
    "#ccffff",
    "#ffccff",
  ];
  return colors[index % colors.length];
};

const { Header, Content, Sider } = Layout;

const ITEMS_PER_PAGE = 10; // Set the number of items per page

const toInitcap = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="custom-prev-arrow" onClick={onClick}>
      Prev
    </button>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="custom-next-arrow" onClick={onClick}>
      Next
    </button>
  );
};

const CustomCard = ({ backgroundColor, title, content, link, linkText }) => (
  <Card
    bordered={false}
    style={{
      background: `linear-gradient(135deg, ${backgroundColor}, ${backgroundColor} 80%)`,
      color: "#000",
      minHeight: "20px",
      borderRadius: "24px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      fontFamily: `"SF Pro Display", "Helvetica Neue", Arial, sans-serif`,
      padding: "2px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    bodyStyle={{
      textAlign: "left",
    }}
  >
    <div style={{ textAlign: "left" }}>
      <p style={{ fontWeight: "700", fontSize: "18px", textTransform: "capitalize", marginBottom: "6px", lineHeight: "1.2em" }}>
        {title}
      </p>
      <p style={{ fontSize: "15px", marginBottom: "8px", lineHeight: "1.4em" }}>
        {content}
      </p>
      {linkText && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#007AFF",
            fontSize: "14px",
            textDecoration: "underline",
          }}
        >
          {linkText}
        </a>
      )}
    </div>
  </Card>
);

const CustomClientCard = ({ backgroundColor, title, content }) => (
  <Card
    bordered={false}
    style={{
      background: `linear-gradient(135deg, ${backgroundColor}, ${backgroundColor} 80%)`,
      color: "#000",
      minHeight: "20px",
      borderRadius: "24px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      fontFamily: `"SF Pro Display", "Helvetica Neue", Arial, sans-serif`,
      padding: "2px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    bodyStyle={{
      textAlign: "left",
    }}
  >
    <div style={{ textAlign: "left" }}>
      <p
        style={{
          fontWeight: "700",
          fontSize: "18px",
          textTransform: "capitalize",
          marginBottom: "6px",
          lineHeight: "1.2em",
        }}
      >
        {title}
      </p>
      <p style={{ fontSize: "15px", marginBottom: "8px", lineHeight: "1.4em" }}>
        {content}
      </p>
    </div>
  </Card>
);


const CustomWeaknessesCard = ({ backgroundColor, title, content }) => (
  <Card
    bordered={false}
    style={{
      background: `linear-gradient(135deg, ${backgroundColor}, ${backgroundColor} 80%)`,
      color: "#000",
      minHeight: "20px",
      borderRadius: "24px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      fontFamily: `"SF Pro Display", "Helvetica Neue", Arial, sans-serif`,
      padding: "2px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    bodyStyle={{
      textAlign: "left",
    }}
  >
    <div style={{ textAlign: "left" }}>
      <p
        style={{
          fontWeight: "700",
          fontSize: "18px",
          textTransform: "capitalize",
          marginBottom: "6px",
          lineHeight: "1.2em",
        }}
      >
        {title}
      </p>
      <p style={{ fontSize: "15px", marginBottom: "8px", lineHeight: "1.4em" }}>
        {content}
      </p>
    </div>
  </Card>
);

const CustomPartnersCard = ({ backgroundColor, title, content }) => (
  <Card
    bordered={false}
    style={{
      background: `linear-gradient(135deg, ${backgroundColor}, ${backgroundColor} 80%)`,
      color: "#000",
      minHeight: "20px",
      borderRadius: "24px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      fontFamily: `"SF Pro Display", "Helvetica Neue", Arial, sans-serif`,
      padding: "2px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    bodyStyle={{
      textAlign: "left",
    }}
  >
    <div style={{ textAlign: "left" }}>
      <p
        style={{
          fontWeight: "700",
          fontSize: "18px",
          textTransform: "capitalize",
          marginBottom: "6px",
          lineHeight: "1.2em",
        }}
      >
        {title}
      </p>
      <p style={{ fontSize: "15px", marginBottom: "8px", lineHeight: "1.4em" }}>
        {content}
      </p>
    </div>
  </Card>
);

const CustomStrengthsCard = ({ backgroundColor, title, content }) => (
  <Card
    bordered={false}
    style={{
      background: `linear-gradient(135deg, ${backgroundColor}, ${backgroundColor} 80%)`,
      color: "#000",
      minHeight: "20px",
      borderRadius: "24px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      fontFamily: `"SF Pro Display", "Helvetica Neue", Arial, sans-serif`,
      padding: "2px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    bodyStyle={{
      textAlign: "left",
    }}
  >
    <div style={{ textAlign: "left" }}>
      <p
        style={{
          fontWeight: "700",
          fontSize: "18px",
          textTransform: "capitalize",
          marginBottom: "6px",
          lineHeight: "1.2em",
        }}
      >
        {title}
      </p>
      <p style={{ fontSize: "15px", marginBottom: "8px", lineHeight: "1.4em" }}>
        {content}
      </p>
    </div>
  </Card>
);


const CustomIntegrationsCard = ({ backgroundColor, title, content }) => (
  <Card
    bordered={false}
    style={{
      background: `linear-gradient(135deg, ${backgroundColor}, ${backgroundColor} 80%)`,
      color: "#000",
      minHeight: "20px",
      borderRadius: "24px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      fontFamily: `"SF Pro Display", "Helvetica Neue", Arial, sans-serif`,
      padding: "2px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    bodyStyle={{
      textAlign: "left",
    }}
  >
    <div style={{ textAlign: "left" }}>
      <p
        style={{
          fontWeight: "700",
          fontSize: "18px",
          textTransform: "capitalize",
          marginBottom: "6px",
          lineHeight: "1.2em",
        }}
      >
        {title}
      </p>
      <p style={{ fontSize: "15px", marginBottom: "8px", lineHeight: "1.4em" }}>
        {content}
      </p>
    </div>
  </Card>
);

const CustomCompetitorsCard = ({ backgroundColor, title, content }) => (
  <Card
    bordered={false}
    style={{
      background: `linear-gradient(135deg, ${backgroundColor}, ${backgroundColor} 80%)`,
      color: "#000",
      minHeight: "20px",
      borderRadius: "24px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      fontFamily: `"SF Pro Display", "Helvetica Neue", Arial, sans-serif`,
      padding: "2px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    bodyStyle={{
      textAlign: "left",
    }}
  >
    <div style={{ textAlign: "left" }}>
      <p
        style={{
          fontWeight: "700",
          fontSize: "18px",
          textTransform: "capitalize",
          marginBottom: "6px",
          lineHeight: "1.2em",
        }}
      >
        {title}
      </p>
      <p style={{ fontSize: "15px", marginBottom: "8px", lineHeight: "1.4em" }}>
        {content}
      </p>
    </div>
  </Card>
);

const CustomJobsCard = ({ backgroundColor, title, content }) => (
  <Card
    bordered={false}
    style={{
      background: `linear-gradient(135deg, ${backgroundColor}, ${backgroundColor} 80%)`,
      color: "#000",
      minHeight: "20px",
      borderRadius: "24px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      fontFamily: `"SF Pro Display", "Helvetica Neue", Arial, sans-serif`,
      padding: "2px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    bodyStyle={{
      textAlign: "left",
    }}
  >
    <div style={{ textAlign: "left" }}>
      <p
        style={{
          fontWeight: "700",
          fontSize: "18px",
          textTransform: "capitalize",
          marginBottom: "6px",
          lineHeight: "1.2em",
        }}
      >
        {title}
      </p>
      <p style={{ fontSize: "15px", marginBottom: "8px", lineHeight: "1.4em" }}>
        {content}
      </p>
    </div>
  </Card>
);


const ReviewCard = ({ company, rating, feedback, reviewDate, reviewType, username, url }) => (
  <Card
    bordered={false}
    style={{
      background: `linear-gradient(135deg, #f0f8ff, #e6e6fa 80%)`,  // Alternate soft gradient
      color: "#000",
      minHeight: "120px",  // Adjusted height for better display of content
      borderRadius: "24px",  // Rounded corners
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",  // Soft shadow
      marginBottom: "16px",
      padding: "16px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    bodyStyle={{
      textAlign: "left",
    }}
  >
    <div>
      {/* Company name in uppercase and rating in bold */}
      <p style={{ fontWeight: "700", fontSize: "18px", marginBottom: "4px" }}>
        {company.toUpperCase()} • <strong>{rating} Stars</strong>
      </p>
      
      {/* Feedback */}
      <p style={{ fontSize: "15px", marginBottom: "8px" }}>
        {feedback}
      </p>
      
      {/* Review date, type, and username */}
      <p style={{ color: "#555", fontSize: "14px", marginBottom: "4px" }}>
        {reviewDate} • {reviewType} • {username}
      </p>
      
      {/* See the Review link */}
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#007AFF", textDecoration: "underline", fontSize: "14px" }}
      >
        SEE THE REVIEW
      </a>
    </div>
  </Card>
);

const NewsCard = ({ backgroundColor, title, content, link, linkText, date }) => (
  <Card
    bordered={false}
    style={{
      background: `linear-gradient(135deg, ${backgroundColor}, ${backgroundColor} 80%)`,  // Soft gradient
      color: "#000",
      minHeight: "20px",  // Slightly taller for more content
      borderRadius: "24px",  // Larger rounded corners
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",  // Softer, larger shadow
      fontFamily: `"SF Pro Display", "Helvetica Neue", Arial, sans-serif`,
      padding: "2px",  // Consistent padding
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    bodyStyle={{
      textAlign: "left",
    }}
  >
    <div style={{ textAlign: "left" }}>
      <p
        style={{
          fontWeight: "700",  // Bolder title
          fontSize: "18px",  // Slightly larger font for better readability
          textTransform: "capitalize",
          marginBottom: "6px",  // Slightly more space between elements
          lineHeight: "1.2em",  // Tighter line spacing
        }}
      >
        {title}
      </p>
      <p style={{ fontSize: "15px", marginBottom: "8px", lineHeight: "1.4em" }}>
        {content}
      </p>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#007AFF",  // Apple's blue link color
          fontSize: "14px", 
          textDecoration: "underline",
        }}
      >
        {linkText || "View More"}
      </a>
      <p style={{ color: "#999999", fontSize: "14px", marginTop: "8px" }}>
        {date} {/* Display the date inside the card */}
      </p>
    </div>
  </Card>
);

const menuToSheetMapping = {
  "General Information": "General Information",
  News: "News",
  Linkedin: "Linkedin Post Analysis",
  Functionality: "Functionality",
  Events: "Online-Offline-Events-Webinars",
  "Video Analysis": "Video Analysis",
  "Employee Feedback": "Employee Feedback",
  Reviews: "Review Platform Analysis",
 // Strategy: "Review Platform Strategy",
  "Google Ads": "GoogleAdsAnalysis",
  Pricing: "Historic Pricing Analysis",
  "Ask AI": "Ask AI",
  "Dashboard Summary": "Dashboard Summary",
  Jobs: "OpenPositions",
  Clients: "Clients",
  "Weaknesses" : "Weaknesses",
  "Strengths" : "Strengths",
  "Integrations" : "Integrations",
  "Competitors" : "Competitors",
  "Price Changes": "Price Changes",
  "Ecosystem":"Ecosystem",
  "Partners": "Partners"
};

const sectionColors = {
  "General Information": "#1e90ff",
  News: "#ff7f50",
  "Linkedin Post Analysis": "#3cb371",
  Functionality: "#dda0dd",
  "Online-Offline-Events-Webinars": "#ff6347",
  "Video Analysis": "#4682b4",
  "Employee Feedback": "#ff69b4",
  "Review Platform Analysis": "#8a2be2",
  //"Review Platform Strategy": "#5f9ea0",
  "Historic Pricing Analysis": "#7b68ee",
  Clients: "#6b8e23",
};

const parseCSVFiles = async () => {
  const files = [
    "Chatmeter_analysis.csv",
    "Gatherup_analysis.csv",
    "Localyser_analysis.csv",
    "Ovation_analysis.csv",
    "Partoo_analysis.csv",
    "Reviewshake_analysis.csv",
    "Reviewtrackers_analysis.csv",
    "Synup_analysis.csv",
    "Vendasta_analysis.csv",
  ];

  const data = await Promise.all(
    files.map(async (file) => {
      const response = await fetch(`/${file}`);
      const text = await response.text();
      const parsed = d3.csvParse(text);
      return { company: file.split("_")[0], data: parsed };
    })
  );

  return data;
};

const Dashboard = () => {
  const [data, setData] = useState({});
  const [selectedMenu, setSelectedMenu] = useState("Dashboard Summary");
  const [aiResponse, setAIResponse] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [strengthsFilteredData, setStrengthsFilteredData] = useState([]);
  const [playlistsFilteredData, setPlaylistsFilteredData] = useState([]); // different filtering for playlists
  const [companyNames, setCompanyNames] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const carouselRef = React.useRef(null);
  const [paginatedData, setPaginatedData] = useState([]);
  const [strengthsPaginatedData, setStrengthsPaginatedData] = useState([]);
  const [playlistsPaginatedData, setPlaylistsPaginatedData] = useState([]); // different pagination for playlists
  const [filteredCompanies, setFilteredCompanies] = useState(["All"]);
  const [current, setCurrent] = useState(1); // Default to page 1
  const [events, setEvents] = useState([]); // Add this line
  const { customer_ObjectId } = useParams(); // Extract customer_ObjectId from the route parameters
  const [functionality, setFunctionality] = useState([]); // for functionality
  const navigate = useNavigate();
  const objectId = sessionStorage.getItem("objectId"); // Use the objectId to validate the session.
  const [news, setNews] = useState([]); // For News tab data
  const [linkedin, setLinkedin] = useState([]);
  const [employeeFeedbacks, setEmployeeFeedbacks] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [general_informations, setGeneralInformations] = useState([]);
  const [googleAds, setGoogleAds] = useState([]);
  const [pricing, setPricing] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [clients, setClients] = useState([]); // clients
  const [weaknesses,setWeaknesses] = useState([]); // weaknesses
  const [partners,setPartners] = useState([]); // partners
  const [strengths,setStrengths] = useState([]); // strengths
  const [integrations,setIntegrations] = useState([]); // integrations
  const [competitors,setCompetitors] = useState([]); // competitors
  const [playlists, setPlaylists] = useState([]);   // New state for playlists and audio analyses
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalPlaylists, setTotalPlaylists] = useState(0); // Total number of playlists
  const [ads, setAds] = useState([]); // ads
  const [zoomedImage, setZoomedImage] = useState(null);
  const [isClientsLoading,setIsClientsLoading] = useState(true);
  const [isWeaknessesLoading,setIsWeaknessesLoading] = useState(true);
  const [isPartnersLoading,setIsPartnersLoading] = useState(true);
  const [isStrengthsLoading,setIsStrengthsLoading] = useState(true); // strengths
  const [isIntegrationsLoading,setIsIntegrationsLoading] = useState(true); // integrations
  const [isCompetitorsLoading,setIsCompetitorsLoading] = useState(true); // competitors
  const [isJobsLoading,setIsJobsLoading] = useState(true);
  const [isAdsLoading, setIsAdsLoading] = useState(true);
  const [isPriceChangesLoading,setIsPriceChangesLoading] = useState(true);
  const [isAssociationsLoading,setIsAssociationsLoading] = useState(true);
  const [isGeneralInformationsLoading, setIsGeneralInfromationsLoading] = useState(true);
  const [isPriceLoading, setIsPriceLoading] = useState(true);
  const [isLinkedinLoading,setIsLinkedinLoading] = useState(true);
  const [isVideoAnalysisLoading, setIsVideoAnalysisLoading] = useState(false); // loader for video analysis
  const [isEmployeeFeedbackLoading,setIsEmployeFeedbackLoading] = useState(false); // loader for employee feedback
  const [isReviewsLoading,setIsReviewsLoading] = useState(false); // loader for reviews
  const [isEventsLoading,setIsEventsLoading] = useState(false); // loader for events
  const [isFunctionalityLoading,setIsFunctionalityLoading] = useState(false); // loader for functionality
  const [isNewsLoading,setIsNewsLoading] = useState(false); // loader for news
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null); // State for date range
  const [battlecards, setBattlecards] = useState([]);  // For storing all fetched battlecards
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);

  const [selectedReviewFeedbackType, setSelectedReviewFeedbackType] = useState(null); // for review page filters
  const [selectedReviewRating, setSelectedReviewRating] = useState(null); // for review page filters

  const [filteredRawReviews, setFilteredRawReviews] = useState([]); // filter for rawreviews
  const [paginatedRawReviews, setPaginatedRawReviews] = useState([]); // pagination for rawreviews
  const[functionalityPaginatedData, setFunctionalityPaginatedData] = useState([]);
  const[functionalityFilteredData, setFunctionalityFilteredData] = useState([]);
  const[eventsPaginatedData, setEventsPaginatedData] = useState([]);
  const[eventsFilteredData, setEventsFilteredData] = useState([]);

  const[linkedinPaginatedData, setLinkedinPaginatedData] = useState([]);
  const[linkedinFilteredData, setLinkedinFilteredData] = useState([]);

  const [homepageScreenshots, setHomepageScreenshots] = useState([]); // homepage
  const [isHomepageLoading, setIsHomepageLoading] = useState(false); // homepage

  const handleFeedbackCompanyChange = (value) => setSelectedCompany(value);
  const handleFeedbackTypeChange = (value) => setSelectedFeedbackType(value);
  const handleFeedbackRatingChange = (value) => setSelectedRating(value);

  const handleReviewCompanyChange = (value) => setSelectedCompany(value); // for review page filters
  const handleReviewTypeChange = (value) => setSelectedReviewFeedbackType(value); // for review page filters
  const handleReviewRatingChange = (value) => setSelectedReviewRating(value); // for review page filters
  const [isBattlecardsLoading, setIsBattlecardsLoading] = useState(false);
  const [priceChangesData, setPriceChangesData] = useState([]);
  const [associationsData, setAssociationsData] = useState([]);
  const [refreshAssociations, setRefreshAssociations] = useState(false);


  // Define state for each menu toggle
  const [showKeyTakeaways, setShowKeyTakeaways] = useState({
    Strengths: false,
    Weaknesses: false,
    Integrations: false,
    Functionality: false,
    Events: false,
    Clients: false,
    Competitors: false,
    Partners: false,
  });

  const [filteredBattlecards, setFilteredBattlecards] = useState([]);



  const handleZoom = (imageUrl) => {
   setZoomedImage(imageUrl); // Store the clicked image URL
  };

  // Function to update toggle state
  const handleToggleChange = (menu) => {
    setShowKeyTakeaways((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };



 

  const handleCompanyFilterChange = (selectedCompanies) => {
    setFilteredCompanies(selectedCompanies);
    let newFilteredData;

    if (selectedCompanies.includes("All")) {
      newFilteredData = filteredData; // Use the fetched LinkedIn data
    } else {
      newFilteredData = filteredData.filter((post) =>
        selectedCompanies.includes(post.company)
      );
    }

    setFilteredData(newFilteredData);
    setPaginatedData(newFilteredData.slice(0, 5)); // Set the first page of data
  };

  const handleLinkedinCompanyFilterChange = (selectedCompanies) => {
    setFilteredCompanies(selectedCompanies);
    let newFilteredData;
  
    if (selectedCompanies.includes("All")) {
      newFilteredData = linkedin; // Use the LinkedIn data instead of filteredData
    } else {
      newFilteredData = linkedin.filter((post) =>
        selectedCompanies.includes(post.company)
      );
    }
  
    setLinkedinFilteredData(newFilteredData);
    setLinkedinPaginatedData(newFilteredData.slice(0, 5)); // Set the first page of data
  };

  const handleJobsFilterChange = (selectedCompanies) => {
    setFilteredCompanies(selectedCompanies);
    let newFilteredData;
  
    if (selectedCompanies.includes("All")) {
      newFilteredData = jobs; // Use the full jobs dataset
    } else {
      newFilteredData = jobs.filter((job) =>
        selectedCompanies.includes(job.company)
      );
    }
  
    setFilteredData(newFilteredData);
    setPaginatedData(newFilteredData.slice(0, ITEMS_PER_PAGE)); // Set the first page of filtered data
    setCurrentPage(1); // Reset to the first page
  };
 
  
  useEffect(() => {
    if (selectedMenu === "Price Changes")
    {
    const fetchPriceChanges = async () => {
      try {
        // Fetch customer data
        setIsPriceChangesLoading(true);
        const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
        const customerData = customerResponse.data;
  
        // Fetch ads using the ads ObjectIds
        const priceChangesResponse = await axios.post(`${BASE_URL}/api/priceChangesByIds`, {
          ids: customerData.price_changes, // Assuming ads array contains ObjectIds of ads
        });
  
        setPriceChangesData(priceChangesResponse.data || []); // Fallback to empty array if no ads
        setFilteredData(priceChangesResponse.data || []); // Ensure filtered data is not undefined
        setPaginatedData((priceChangesResponse.data || []).slice(0, ITEMS_PER_PAGE)); // Paginate the first page
        setCurrentPage(1); // Reset to the first page
        setIsPriceChangesLoading(false);
      } catch (error) {
        console.error("Error fetching ads:", error);
        setIsPriceChangesLoading(false);
      }
    };
  
    if (priceChangesData.length == 0) {
      fetchPriceChanges();
    } 
    else{
      setFilteredData(priceChangesData);
      setPaginatedData(ads.slice(0, ITEMS_PER_PAGE));
      setCurrentPage(1);
    }
  }
  }, [selectedMenu, customer_ObjectId]);


  useEffect(() => {
    if (selectedMenu === "Ecosystem")
    {
    const fetchAssociations = async () => {
      try {
        // Fetch customer data
        setIsAssociationsLoading(true);
        const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
        const customerData = customerResponse.data;
  
        // Fetch ads using the ads ObjectIds
        const associationsResponse = await axios.post(`${BASE_URL}/api/associationsByIds`, {
          ids: customerData.associations, // Assuming ads array contains ObjectIds of associtations
        });
  
        setAssociationsData(associationsResponse.data || []); // Fallback to empty array if no ads
        setFilteredData(associationsResponse.data || []); // Ensure filtered data is not undefined
        setPaginatedData((associationsResponse.data || []).slice(0, ITEMS_PER_PAGE)); // Paginate the first page
        setCurrentPage(1); // Reset to the first page
        setIsAssociationsLoading(false);
        setRefreshAssociations(false); // Reset refresh flag
      } catch (error) {
        console.error("Error fetching ads:", error);
        setIsAssociationsLoading(false);
      }
    };
  
    if (associationsData.length == 0) {
      fetchAssociations();
    } 
    else{
      setFilteredData(associationsData);
      setPaginatedData(ads.slice(0, ITEMS_PER_PAGE));
      setCurrentPage(1);
    }
  }
  }, [selectedMenu, customer_ObjectId,refreshAssociations]);
  
 
  useEffect(() => {
    if (selectedMenu === 'Dashboard Summary') {
      const fetchBattlecards = async () => {
        setIsBattlecardsLoading(true); // Start loading
        try {
          // Fetch customer data
          const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
          const customerData = customerResponse.data;
          console.log('BattleCards Customer Response Data', customerResponse.data);
  
          // Fetch battlecards using the ObjectIds from customer data
          const battlecardsResponse = await axios.post(`${BASE_URL}/api/battlecardsByIds`, {
            ids: customerData.battlecard_summaries,
          });
  
          console.log('BattleCards Response Data', battlecardsResponse.data);
  
          setBattlecards(battlecardsResponse.data);
  
          // Filter battlecards for Dashboard Summary view
          const dashboardBattlecards = battlecardsResponse.data.filter(battlecard =>
            battlecard.battlecard_name.startsWith('Dashboard')
          );
  
          setFilteredBattlecards(dashboardBattlecards);
          setPaginatedData(dashboardBattlecards.slice(0, ITEMS_PER_PAGE)); // Paginate the first 5 battlecards
          setCurrentPage(1); // Start on the first page
  
          setIsBattlecardsLoading(false); // End loading
        } catch (error) {
          console.error('Error fetching battlecard summaries:', error);
          setIsBattlecardsLoading(false); // End loading on error
        }
      };
  
      if (battlecards.length === 0) {
        fetchBattlecards();
        console.log('Fetching battlecards for the first time', battlecards);
      } else {
        setFilteredBattlecards(battlecards.filter(battlecard =>
          battlecard.battlecard_name.startsWith('Dashboard')
        ));
        setPaginatedData(battlecards.slice(0, ITEMS_PER_PAGE)); // Use existing data
        setCurrentPage(1); // Reset to the first page
        console.log('Displaying already loaded battlecards', battlecards);
      }
    }
  }, [customer_ObjectId, selectedMenu, battlecards]);
// Filter battlecards based on toggle state
useEffect(() => {
  if (selectedMenu !== 'Dashboard Summary')  
    {
  const filterBattlecardsByMenu = () => {
    if (!battlecards || battlecards.length === 0) return; // Check if data is available

    // Map the selectedMenu to the corresponding battlecard_name
    const menuMapping = {
      Strengths: "Strengths - Data Categorization",
      Weaknesses: "Weaknesses - Data Categorization",
      Integrations: "Integrations - Data Categorization",
      Functionality: "Functionalities - Data Categorization",
      Events: "Events - Data Categorization",
      Clients: "Clients - Data Categorization",
      Competitors: "Competitors - Data Categorization",
      Partners: "Partners - Data Categorization"
    };

    // Get the battlecard_name for the selectedMenu
    const selectedBattlecardName = menuMapping[selectedMenu];

    if (!selectedBattlecardName) {
      setFilteredBattlecards([]); // If no mapping exists, clear the filtered battlecards
      return;
    }

    // Filter the battlecards for the selected menu
    const filtered = battlecards.filter(
      (card) => card.battlecard_name === selectedBattlecardName
    );

    // Update the state with the filtered battlecards
    setFilteredBattlecards(filtered);
    console.log("Filtered battlecards for selected menu:", filtered);
  };

  filterBattlecardsByMenu();
  }
}, [battlecards, selectedMenu]);

// Fetch playlists and audio analyses from the new endpoint
useEffect(() => {
  if (selectedMenu === "Video Analysis") {
    const fetchPlaylistsData = async () => {
      try {
        setIsVideoAnalysisLoading(true); // Start loading
        const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
        const customerData = customerResponse.data;
          
        // Fetch all playlists from the server
        const playlistsResponse = await axios.post(`${BASE_URL}/api/audioAnalysisByIds`, {
          ids: customerData.audio_analyses,
        });
        
        console.log('playlistdata fetched', playlistsResponse.data);

        // Update the state with fetched data directly from playlistsResponse.data
        setPlaylists(playlistsResponse.data); 
        setPlaylistsFilteredData(playlistsResponse.data); // Save all the fetched data
        setPlaylistsPaginatedData(playlistsResponse.data.slice(0, ITEMS_PER_PAGE)); // Display the first page initially
        setTotalPlaylists(playlistsResponse.data.length); // Store the total number of playlists
        setIsVideoAnalysisLoading(false); // End loading
      } catch (error) {
        console.error("Error fetching Playlists data:", error);
        setIsVideoAnalysisLoading(false); // End loading in case of error
      }
    };

    // Check if data is already available
    if (playlists.length === 0) {
      fetchPlaylistsData(); // Fetch data if it's not already available
      console.log('setting playlists data for first time:', playlists);
    } else {
      // If data is available, still update the state
      console.log('setting filtered data for playlists', playlists);
      setPlaylistsFilteredData(playlists); // Ensure the filteredData is set
      setPlaylistsPaginatedData(playlists.slice(0, ITEMS_PER_PAGE)); // Set paginated data
      setTotalPlaylists(playlists.length); // Ensure the total count is accurate
      console.log('filtered data for playlists:', playlists);
      console.log('paginated data playlists:', playlists.slice(0, ITEMS_PER_PAGE));
      console.log('totalplaylists:', playlists.length);
    }
  }
}, [selectedMenu, customer_ObjectId, playlists.length]);

 useEffect(() => {
  if (selectedMenu == "Events"){
  const fetchEvents = async () => {
    try {
      // Fetch customer data
      setIsEventsLoading(true); // Start loading

      const customerResponse = await axios.get(
        `${BASE_URL}/api/customers/${customer_ObjectId}`
      );
      const customerData = customerResponse.data;

      console.log ('event ids from useEffect',customerData.events);
      // Fetch events using the events ObjectIds
      const eventsResponse = await axios.post(`${BASE_URL}/api/eventsByIds`, {
        ids: customerData.events,
      });
      setEvents(eventsResponse.data);
      
      // Set paginated and filtered data for events
      setEventsPaginatedData(eventsResponse.data.slice(0, 5)); // Paginate first 5 events
      setEventsFilteredData(eventsResponse.data);
      setIsEventsLoading(false); 
    } catch (error) {
      console.error("Error fetching events:", error);
      setIsEventsLoading(false); 

    }
  };
  if (events.length == 0)
  {
  fetchEvents();
  console.log('Fetching events for the first time', events);
  }
  else
  {
    setEventsPaginatedData(events.slice(0,5));
    setEventsFilteredData(events);
    console.log('Displaying already set events', events);
  }
}
}, [selectedMenu,customer_ObjectId]);

useEffect(() => {
  if (selectedMenu == "Functionality"){
  const fetchFunctionalities = async () => {
    try {
      setIsFunctionalityLoading(true);
      // Fetch customer data
      const customerResponse = await axios.get(
        `${BASE_URL}/api/customers/${customer_ObjectId}`
      );
      const customerData = customerResponse.data;

      // Fetch functionalities using the functionalities ObjectIds
      const functionalitiesResponse = await axios.post(
        `${BASE_URL}/api/functionalitiesByIds`,
        {
          ids: customerData.functionalities,
        }
      );
      setFunctionality(functionalitiesResponse.data);

      // Set paginated and filtered data for functionalities
      setFunctionalityPaginatedData(functionalitiesResponse.data.slice(0, 5)); 
      setFunctionalityFilteredData(functionalitiesResponse.data);
      setIsFunctionalityLoading(false);

    } catch (error) {
      console.error("Error fetching functionalities:", error);
      setIsFunctionalityLoading(false);
    }
  };
  if (functionality.length == 0) {
    fetchFunctionalities();
  }
  else
  { console.log('Functionality data already loaded',functionality);
    setFunctionalityPaginatedData(functionality.slice(0, 5));
    setFunctionalityFilteredData(functionality);
    console.log('Filtered data set in functionality load:', filteredData)
  }
}

}, [selectedMenu,customer_ObjectId]);


useEffect(() => {
  if (selectedMenu === "Linkedin") {
  const fetchData = async () => {
    
      try {
        setIsLinkedinLoading(true); // Start loading
        // Fetch customer data
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        const customerData = customerResponse.data;

        // Fetch LinkedIn posts using the linkedin_posts ObjectIds
        const linkedinPostsResponse = await axios.post(
          `${BASE_URL}/api/linkedinPostsByIds`,
          {
            ids: customerData.linkedin_posts,
          }
        );
        setLinkedin(linkedinPostsResponse.data);

        setLinkedinPaginatedData(linkedinPostsResponse.data.slice(0, 5));
        setLinkedinFilteredData(linkedinPostsResponse.data);
        setCurrent(1);
        setIsLinkedinLoading(false); // Finish loading
      } catch (error) {
        console.error("Error fetching LinkedIn posts:", error);
        setIsLinkedinLoading(false); // Finish loading in case of error
      }
    
  };

  // Call fetchData only if the LinkedIn data is not already loaded
  if (linkedin.length === 0) {
    fetchData();
    console.log("Setting linkedin data the first time:", linkedin);
  } else {
    // If data is available, update the state
    console.log("Setting filtered data for linkedin", linkedin);
    setLinkedinFilteredData(linkedin); // Ensure the filtered data is set
    setLinkedinPaginatedData(linkedin.slice(0, 5)); // Set paginated data
    console.log("Filtered data for linkedin:", linkedin);
    console.log("Paginated data for linkedin:", linkedin.slice(0, 5));
  }
}
}, [selectedMenu, customer_ObjectId, linkedin]);


  useEffect(() => {
    if (selectedMenu === "General Information"){
    const fetchData = async () => {
      try {
        setIsGeneralInfromationsLoading(true);
        // Fetch customer data
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        const customerData = customerResponse.data;

        // Fetch general informations using the general_informations ObjectIds
        const generalInformationsResponse = await axios.post(
          `${BASE_URL}/api/generalInformationsByIds`,
          {
            ids: customerData.general_informations,
          }
        );
        setGeneralInformations(generalInformationsResponse.data);
        console.log("general_informations set to", general_informations);
        setPaginatedData(generalInformationsResponse.data.slice(0, 5));
        setFilteredData(generalInformationsResponse.data);
        setCurrent(1);
        setIsGeneralInfromationsLoading(false);
      } catch (error) {
        console.error("Error fetching general information:", error);
        setIsGeneralInfromationsLoading(false);
      }
    };

    if (general_informations.length == 0) {
      fetchData();
    } else
    {
      setPaginatedData(general_informations.slice(0,5));
      setFilteredData(general_informations);
      setCurrent(1);
    }
  }
  }, [selectedMenu, customer_ObjectId]);

  useEffect(() => {
    if (selectedMenu == "News")
    {
    const fetchNewsData = async () => {
      try {
        setIsNewsLoading(true);
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        const customerData = customerResponse.data;

        // Fetch news using the news ObjectIds
        const newsResponse = await axios.post(`${BASE_URL}/api/newsByIds`, {
          ids: customerData.news,
        });
        setNews(newsResponse.data);
        setIsNewsLoading(false);
      } catch (error) {
        console.error("Error fetching news data:", error);
        setIsNewsLoading(false);
      }
    };

    if (news.length == 0) {
      fetchNewsData();
      console.log('Setting news for the first time',news);
    }
    else{
      setNews(news);
      console.log('Getting already fetched news',news)
    }
  }
  }, [selectedMenu, customer_ObjectId]);

  useEffect(() => {
    if (selectedMenu == "Employee Feedback"){
    const fetchEmployeeFeedbackData = async () => {
      try {
        setIsEmployeFeedbackLoading(true); // Start loading

        // Fetch customer data
        const customerResponse = await axios.get(
          `${BASE_URL}/api/customers/${customer_ObjectId}`
        );
        const customerData = customerResponse.data;

        // Fetch Employee Feedbacks using the employee_feedbacks ObjectIds
        const employeeFeedbackResponse = await axios.post(
          `${BASE_URL}/api/employeeFeedbacksByIds`,
          {
            ids: customerData.employee_feedbacks,
          }
        );
        setEmployeeFeedbacks(employeeFeedbackResponse.data);

        // Initialize pagination data for the first page
        setPaginatedData(
          employeeFeedbackResponse.data.slice(0, ITEMS_PER_PAGE)
        );
        setFilteredData(employeeFeedbackResponse.data);

        setCurrentPage(1); // Start on page 1
        setIsEmployeFeedbackLoading(false); // End loading
      } catch (error) {
        console.error("Error fetching Employee Feedback data:", error);
        setIsEmployeFeedbackLoading(false); // End loading in case of error

      }
    };

    if (employeeFeedbacks.length === 0) {
      fetchEmployeeFeedbackData();
      console.log('Fetching employee feedback for the first time',employeeFeedbacks);
    }
    else {
      setPaginatedData(employeeFeedbacks.slice(0, ITEMS_PER_PAGE));
      setFilteredData(employeeFeedbacks);
      setCurrentPage(1); // Start on page 1
      console.log('Load ready employee feedbacks data',employeeFeedbacks);

    }

  }
  }, [selectedMenu, customer_ObjectId]);

  useEffect(() => {
    if (selectedMenu === "Reviews") {
      const fetchRawReviewsData = async () => {
        try {
          setIsReviewsLoading(true); // Start loading

          console.log("Fetching customer data for ID:", customer_ObjectId);
  
          // Fetch customer data
          const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
          const customerData = customerResponse.data;
  
          // Check if the customer has associated reviews
          if (!customerData.rawreviews || customerData.rawreviews.length === 0) {
            console.warn("No reviews found for this customer.");
            return;
          }
  
          // Fetch raw reviews using the rawreviews ObjectIds
          console.log("Fetching raw reviews with IDs:", customerData.rawreviews);
          const reviewsResponse = await axios.post(`${BASE_URL}/api/rawreviewsByIds`, {
            ids: customerData.rawreviews,
          });
  
          const reviews = reviewsResponse.data;
          console.log("Raw Reviews data received:", reviews);
  
          // Update the state with fetched data
          setReviews(reviews);
          setFilteredRawReviews(reviews); // Save all the fetched data
          setPaginatedRawReviews(reviews.slice(0, ITEMS_PER_PAGE)); // Display the first page initially
          setIsReviewsLoading(false);
        } catch (error) {
          console.error("Error fetching Raw Reviews data:", error);
          setIsReviewsLoading(false);
        }
      };
  
      // Check if raw reviews data is already available
      if (reviews.length === 0) {
        fetchRawReviewsData(); // Fetch data if it's not already available
        console.log("Setting raw reviews data for the first time:", reviews);
      } else {
        // If data is available, still update the state
        console.log("Setting filtered data for raw reviews", reviews);
        setFilteredRawReviews(reviews); // Ensure the filtered data is set
        setPaginatedRawReviews(reviews.slice(0, ITEMS_PER_PAGE)); // Set paginated data
        console.log("Filtered data for raw reviews:", reviews);
        console.log("Paginated data raw reviews:", reviews.slice(0, ITEMS_PER_PAGE));
      }
    }
  }, [selectedMenu, customer_ObjectId, reviews.length]);

 
useEffect(() => {
  if (selectedMenu === 'Pricing') {
  const fetchPricingData = async () => {
    try {
      setIsPriceLoading(true); // Start loading

      // Fetch customer data
      const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
      const customerData = customerResponse.data;

      // Fetch pricing screenshots
      const screenshotsResponse = await axios.post(`${BASE_URL}/api/pricingScreenshotsByIds`, {
        ids: customerData.price_screenshots
      });

      const pricingScreenshots = screenshotsResponse.data;

      // Fetch price summaries
      const screenshotIds = pricingScreenshots.map(screenshot => screenshot._id);
      const summariesResponse = await axios.post(`${BASE_URL}/api/priceSummariesByScreenshotIds`, {
        ids: screenshotIds,
      });

      const priceSummaries = summariesResponse.data;

      // Combine the pricing screenshots and summaries
      const combinedData = pricingScreenshots.map(screenshot => {
        return {
          ...screenshot,
          summary: priceSummaries.find(summary => summary.pricingScreenshotId.includes(screenshot._id)),
        };
      });

      // Flatten screenshots for pagination and set the first page
      const allScreenshots = flattenScreenshots(combinedData);

      setPricing(combinedData);
      setFilteredData(combinedData);
      setPaginatedData(allScreenshots.slice(0, ITEMS_PER_PAGE)); // Set the first page after data is available
      setCurrentPage(1);

      setIsPriceLoading(false); // End loading
    } catch (error) {
      console.error('Error fetching pricing data:', error);
      setIsPriceLoading(false); // Ensure loading stops in case of error
    }
  };
  


  if (pricing.length == 0) {
    fetchPricingData();
  } else
  {
    const allScreenshots = flattenScreenshots(pricing);
    setFilteredData(pricing);
    setPaginatedData(allScreenshots.slice(0,ITEMS_PER_PAGE));
    setCurrentPage(1);
  }
  }
}, [selectedMenu, customer_ObjectId]);

useEffect(() => {
  if (selectedMenu === 'Homepage') {
    const fetchHomepageData = async () => {
      try {
        setIsHomepageLoading(true);
        
        // Fetch customer data
        const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
        const customerData = customerResponse.data;
        console.log("Customer Data:", customerData); // Log customer data

        // Fetch homepage screenshots
        const screenshotsResponse = await axios.post(`${BASE_URL}/api/homepageScreenshotsByIds`, {
          ids: customerData.homepage_screenshots
        });
        const homepageScreenshots = screenshotsResponse.data;
        console.log("Homepage Screenshots:", homepageScreenshots); // Log screenshots data

        // Fetch homepage summaries
        const screenshotIds = homepageScreenshots.map(screenshot => screenshot._id);
        const summariesResponse = await axios.post(`${BASE_URL}/api/homepageSummariesByScreenshotIds`, {
          ids: screenshotIds,
        });
        const homepageSummaries = summariesResponse.data;
        console.log("Homepage Summaries:", homepageSummaries); // Log summaries data

        // Combine the screenshots and summaries
        const combinedData = homepageScreenshots.map(screenshot => {
          return {
            ...screenshot,
            summary: homepageSummaries.find(summary => summary.homepageScreenshotId.includes(screenshot._id)),
          };
        });
        console.log("Combined Data (Screenshots with Summaries):", combinedData); // Log combined data

        // Flatten screenshots for pagination and set the first page
        const allScreenshots = flattenScreenshots(combinedData);
        console.log("Flattened Screenshots for Pagination:", allScreenshots); // Log flattened screenshots

        setHomepageScreenshots(combinedData);
        setFilteredData(combinedData);
        setPaginatedData(allScreenshots.slice(0, ITEMS_PER_PAGE));
        setCurrentPage(1);

        setIsHomepageLoading(false);
      } catch (error) {
        console.error('Error fetching homepage data:', error);
        setIsHomepageLoading(false);
      }
    };

    // Fetch only if there's no existing data for the Homepage menu
    if (homepageScreenshots.length === 0) {
      fetchHomepageData();
    } else {
      const allScreenshots = flattenScreenshots(homepageScreenshots);
      setFilteredData(homepageScreenshots);
      setPaginatedData(allScreenshots.slice(0, ITEMS_PER_PAGE));
      setCurrentPage(1);
    }
  }
}, [selectedMenu, customer_ObjectId]);

 useEffect(() => {
  if (selectedMenu == "Jobs"){
  const fetchJobsData = async () => {
    try {
      setIsJobsLoading(true);
      // Fetch customer data based on customer_ObjectId
      const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
      const customerData = customerResponse.data;

      // Fetch jobs using the job IDs from the customer data
      const jobsResponse = await axios.post(`${BASE_URL}/api/openPositionsByIds`, {
        ids: customerData.open_positions // Assuming jobs are stored in customerData.open_positions
      });

      // Update state with fetched jobs data
      setJobs(jobsResponse.data); // Update jobs state
      setFilteredData(jobsResponse.data);
      setPaginatedData(jobsResponse.data.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
      setIsJobsLoading(false);
    } catch (error) {
      console.error("Error fetching Jobs data:", error);
      setIsJobsLoading(false);
    }
  };

  if (jobs.length == 0) {
    fetchJobsData(); // Call the function if Jobs is selected
  }
  else
  {
    setFilteredData(jobs);
    setPaginatedData(jobs.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
    setCurrentPage(1); // Reset to first page
  }
}
}, [selectedMenu, customer_ObjectId]);

 useEffect(() => {
  if (selectedMenu == "Clients"){
  const fetchClientsData = async () => {
    try {
      // Fetch customer data based on customer_ObjectId
      setIsClientsLoading(true);
      const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
      const customerData = customerResponse.data;

      // Fetch clients using the job IDs from the customer data
      const clientsResponse = await axios.post(`${BASE_URL}/api/clientsByIds`, {
        ids: customerData.clients // Assuming clients are stored in customerData.clients
      });

      // Update state with fetched clients data
      setClients(clientsResponse.data); // Update clients state
      setFilteredData(clientsResponse.data);
      setPaginatedData(clientsResponse.data.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
      setIsClientsLoading(false);
    } catch (error) {
      console.error("Error fetching Clients data:", error);
      setIsClientsLoading(false);
    }
  };

  if (clients.length == "0") {
    fetchClientsData(); // Call the function if Jobs is selected
  }
  else {
      setFilteredData(clients);
      setPaginatedData(clients.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
  }
}
}, [selectedMenu, customer_ObjectId]);


useEffect(() => {
  if (selectedMenu == "Weaknesses"){
  const fetchWeaknessesData = async () => {
    try {
      // Fetch customer data based on customer_ObjectId
      setIsWeaknessesLoading(true);
      const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
      const customerData = customerResponse.data;

      // Fetch weaknesses using the job IDs from the customer data
      const weaknessesResponse = await axios.post(`${BASE_URL}/api/weaknessesByIds`, {
        ids: customerData.weaknesses // Assuming weaknesses are stored in customerData.weaknesses
      });

      // Update state with fetched weaknesses data
      setWeaknesses(weaknessesResponse.data); // Update weaknesses state
      setFilteredData(weaknessesResponse.data);
      setPaginatedData(weaknessesResponse.data.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
      setIsWeaknessesLoading(false);
      console.log("Weakness data fetched",weaknessesResponse.data);
    } catch (error) {
      console.error("Error fetching weaknesses data:", error);
      setIsWeaknessesLoading(false);
    }
  };

  if (weaknesses.length == "0") {
    fetchWeaknessesData(); // Call the function if Weaknesses
  }
  else {
      setFilteredData(weaknesses);
      setPaginatedData(weaknesses.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
  }
}
}, [selectedMenu, customer_ObjectId]);

useEffect(() => {
  if (selectedMenu == "Partners"){
  const fetchPartnersData = async () => {
    try {
      // Fetch customer data based on customer_ObjectId
      setIsPartnersLoading(true);
      const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
      const customerData = customerResponse.data;

      // Fetch partners using the job IDs from the customer data
      const partnersResponse = await axios.post(`${BASE_URL}/api/partnersByIds`, {
        ids: customerData.partners // Assuming partners are stored in customerData.partners
      });

      // Update state with fetched partners data
      setPartners(partnersResponse.data); // Update Partners state
      setFilteredData(partnersResponse.data);
      setPaginatedData(partnersResponse.data.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
      setIsPartnersLoading(false);
      console.log("Weakness data fetched",partnersResponse.data);
    } catch (error) {
      console.error("Error fetching partners data:", error);
      setIsPartnersLoading(false);
    }
  };

  if (partners.length == "0") {
    fetchPartnersData(); // Call the function if Partners
  }
  else {
      setFilteredData(partners);
      setPaginatedData(partners.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
  }
}
}, [selectedMenu, customer_ObjectId]);


useEffect(() => {
  if (selectedMenu == "Strengths"){
  const fetchStrengthsData = async () => {
    try {
      // Fetch customer data based on customer_ObjectId
      setIsStrengthsLoading(true);
      const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
      const customerData = customerResponse.data;

      // Fetch strengths using the job IDs from the customer data
      const strengthsResponse = await axios.post(`${BASE_URL}/api/strengthsByIds`, {
        ids: customerData.strengths // Assuming strengths are stored in customerData.strengths
      });

      // Update state with fetched strengths data
      setStrengths(strengthsResponse.data); // Update strengths state
      setStrengthsFilteredData(strengthsResponse.data);
      setStrengthsPaginatedData(strengthsResponse.data.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
      setIsStrengthsLoading(false);
      console.log("Strengths data fetched",strengthsResponse.data);
    } catch (error) {
      console.error("Error fetching strengths data:", error);
      setIsStrengthsLoading(false);
    }
  };

  if (strengths.length == "0") {
    fetchStrengthsData(); // Call the function 
  }
  else {
      console.log("Strengths are reloaded as", strengths)
      setStrengthsFilteredData(strengths);
      setStrengthsPaginatedData(strengths.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
  }
}
}, [selectedMenu, customer_ObjectId]);


useEffect(() => {
  if (selectedMenu == "Integrations"){
  const fetchIntegrationsData = async () => {
    try {
      // Fetch customer data based on customer_ObjectId
      setIsIntegrationsLoading(true);
      const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
      const customerData = customerResponse.data;

      // Fetch integrations using the job IDs from the customer data
      const integrationsResponse = await axios.post(`${BASE_URL}/api/integrationsByIds`, {
        ids: customerData.integrations // Assuming integrations are stored in customerData.integrations
      });

      // Update state with fetched integrations data
      setIntegrations(integrationsResponse.data); // Update integrations state
      setFilteredData(integrationsResponse.data);
      setPaginatedData(integrationsResponse.data.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
      setIsIntegrationsLoading(false);
      console.log("Integrations data fetched",integrationsResponse.data);
    } catch (error) {
      console.error("Error fetching integrations data:", error);
      setIsIntegrationsLoading(false);
    }
  };

  if (integrations.length == "0") {
    fetchIntegrationsData(); // Call the function if Integrations
  }
  else {
      setFilteredData(integrations);
      setPaginatedData(integrations.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
  }
}
}, [selectedMenu, customer_ObjectId]);


useEffect(() => {
  if (selectedMenu == "Competitors"){
  const fetchCompetitorsData = async () => {
    try {
      // Fetch customer data based on customer_ObjectId
      setIsCompetitorsLoading(true);
      const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
      const customerData = customerResponse.data;

      // Fetch competitors using the job IDs from the customer data
      const competitorsResponse = await axios.post(`${BASE_URL}/api/competitorsByIds`, {
        ids: customerData.competitors // Assuming competitors are stored in customerData.competitors
      });

      // Update state with fetched competitors data
      setCompetitors(competitorsResponse.data); // Update competitors state
      setFilteredData(competitorsResponse.data);
      setPaginatedData(competitorsResponse.data.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
      setIsCompetitorsLoading(false);
      console.log("Competitors data fetched",competitorsResponse.data);
    } catch (error) {
      console.error("Error fetching competitors data:", error);
      setIsCompetitorsLoading(false);
    }
  };

  if (competitors.length == "0") {
    fetchCompetitorsData(); // Call the function if Competitors
  }
  else {
      setFilteredData(competitors);
      setPaginatedData(competitors.slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to first page
  }
}
}, [selectedMenu, customer_ObjectId]);


useEffect(() => {
  if (selectedMenu === "Google Ads")
  {
  const fetchAdsData = async () => {
    try {
      // Fetch customer data
      setIsAdsLoading(true);
      const customerResponse = await axios.get(`${BASE_URL}/api/customers/${customer_ObjectId}`);
      const customerData = customerResponse.data;

      // Fetch ads using the ads ObjectIds
      const adsResponse = await axios.post(`${BASE_URL}/api/adsByIds`, {
        ids: customerData.ads, // Assuming ads array contains ObjectIds of ads
      });

      setAds(adsResponse.data || []); // Fallback to empty array if no ads
      setFilteredData(adsResponse.data || []); // Ensure filtered data is not undefined
      setPaginatedData((adsResponse.data || []).slice(0, ITEMS_PER_PAGE)); // Paginate the first page
      setCurrentPage(1); // Reset to the first page
      setIsAdsLoading(false);
    } catch (error) {
      console.error("Error fetching ads:", error);
      setIsAdsLoading(false);
    }
  };

  if (ads.length == 0) {
    fetchAdsData();
  } 
  else{
    setFilteredData(ads);
    setPaginatedData(ads.slice(0, ITEMS_PER_PAGE));
    setCurrentPage(1);
  }
}
}, [selectedMenu, customer_ObjectId]);


const handleMenuSelection = ({ key }) => {
  setSelectedMenu(key);

  if (
    [
      'News',
      'LinkedIn',
      'Functionality',
      'Events',
      'Video Analysis',  // Add Video Analysis here
      'Employee Feedback',
      'Reviews',
      'General Information',
      'Google Ads',
      'Pricing',
      'Homepage',
      'Jobs',
      'Clients',
      'Battlecards', // Add Battlecards here
      'Weaknesses',
      'Integrations',
      'Competitors',
      'Dashboard Summary',
      'Price Changes',
      'Partners',
      'Ecosystem'
    ].includes(key)
  ) {
    switch (key) {
      case 'News':
        setFilteredData(news);
        break;
      case 'LinkedIn':
        setFilteredData(linkedin);
        break;
      case 'Functionality':
        setFilteredData(functionality);
        break;
      case 'Events':
        setFilteredData(events);
        break;
      case 'Video Analysis':  // Fetch Video Analysis data
        setPlaylistsFilteredData(playlists);  // Use video analysis data from state
        break;
      case 'Employee Feedback':
        setFilteredData(employeeFeedbacks);
        break;
      case 'Reviews':
        setFilteredData(reviews);
        break;
      case 'General Information':
        setFilteredData(general_informations);
        break;
      case 'Google Ads':
        setFilteredData(ads);
        break;
      case 'Pricing':
        setFilteredData(pricing);
        break;
      case 'Homepage':
        setFilteredData(homepageScreenshots);
        break;
      case 'Jobs':
        setFilteredData(jobs);
        break;
      case 'Clients':
        setFilteredData(clients);
        break;
      case 'Battlecards': // Add handling for Battlecards
        setFilteredData(battlecards); // Assume battlecards is populated in useEffect
        break;
      case 'Weaknesses': // Add handling for Weaknesses
        setFilteredData(weaknesses); // Assume weaknesses is populated in useEffect
        setFilteredBattlecards(filteredBattlecards);
      break;
      case 'Integrations': // Add handling for Integrations
        setFilteredData(integrations); // Assume Integrations is populated in useEffect
        setFilteredBattlecards(filteredBattlecards);
      break;
      case 'Partners': // Add handling for Partners
        setFilteredData(partners); // Assume partners is populated in useEffect
        setFilteredBattlecards(filteredBattlecards);
      break;
      case 'Competitors': // Add handling for Competitors
        setFilteredData(competitors); // Assume Competitors is populated in useEffect
      break;
      case 'Strengths': // Add handling for Strengths
        setStrengthsFilteredData(strengths); // Assume stregths is populated in useEffect
      break;
      case 'Price Changes':
        setFilteredData([]); // Clear any existing data or set defaults if needed
      break;
      case 'Ecosystem':
        setFilteredData(associationsData); // Clear any existing data or set defaults if needed
      break;
      case 'Dashboard Summary': // Add handling for Strengths
       // Filter battlecards where the name starts with "Dashboard"
       const dashboardBattlecards = battlecards.filter(battlecard =>
        battlecard.battlecard_name.startsWith('Dashboard')
        );
        setFilteredBattlecards(dashboardBattlecards);
      break;
      default:
        break;
    }
  } else {
    setFilteredData(data[menuToSheetMapping[key]]);
  }
};

const handleVideoAnalysisPageChange = (page) => {
  const paginatedData = playlistsFilteredData.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );
  setPlaylistsPaginatedData(paginatedData);
  setCurrentPage(page);  // Update current page state
};

  const handleTableChange = (pagination, filters, sorter) => {
  let currentData;
  console.log("Selected Menu:", selectedMenu);

  if (
    [
      'News',
      'LinkedIn',
      'Functionality',
      'Events',
      'Video Analysis',  // Add Video Analysis here
      'Employee Feedback',
      'Reviews',
      'General Information',
      'Google Ads',
      'Pricing',
      'Homepage',
      'Jobs',
      'Clients',
      'Battlecards', // Add Battlecards here
      'Weaknesses',
      'Strengths',
      'Integrations',
      'Competitors',
      'Partners'
    ].includes(selectedMenu)
  ) {
    switch (selectedMenu) {
      case 'News':
        currentData = news;
        break;
      case 'LinkedIn':
        currentData = linkedin;
        break;
      case 'Functionality':
        currentData = functionality;
        break;
      case 'Events':
        currentData = events;
        break;
      case 'Video Analysis':  // Handle Video Analysis data
        currentData = playlists;  // Use video analysis data from state
        break;
      case 'Employee Feedback':
        currentData = employeeFeedbacks;
        break;
      case 'Reviews':
        currentData = reviews;
        break;
      case 'General Information':
        currentData = general_informations;
        break;
      case 'Google Ads':
        currentData = ads;
        break;
      case 'Pricing':
        currentData = pricing;
        break;
      case 'Homepage':
          currentData = homepageScreenshots;
          break;  
      case 'Jobs':
        currentData = jobs;
        break;
      case 'Clients':
        currentData = clients;
        break;
      case 'Weaknesses':
       currentData = weaknesses;
      break;
      case 'Partners':
       currentData = partners;
      break;
      case 'Integrations':
       currentData = integrations;
      break;
      case 'Competitors':
       currentData = competitors;
      break;
      case 'Strengths':
       currentData = strengths;
      break;
      case 'Battlecards': // Add handling for Battlecards
        currentData = battlecards; // Use battlecards data here
        break;
      default:
        break;
    }
  } else {
    currentData = data[menuToSheetMapping[selectedMenu]];
  }

  let filtered = currentData;

  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      filtered = filtered.filter((item) =>
        filters[key].some(
          (filterValue) =>
            filterValue.toLowerCase() === String(item[key]).toLowerCase()
        )
      );
    }
  });

  setFilteredData(filtered);
};


// Assuming `battlecards` is an array of all battlecards fetched from the API
const groupedBattlecards = battlecards.reduce((acc, battlecard) => {
  const company = battlecard.company;
  if (!acc[company]) {
    acc[company] = [];
  }
  acc[company].push(battlecard);
  return acc;
}, {});

const renderEventsContent = () => {
  if (isEventsLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "flex-start", // Align to the top
          height: "100vh", // Full viewport height
          paddingTop: "20px", // Add some spacing from the top
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  const paginatedEvents = eventsFilteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
     {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"  // Center horizontally
        align="middle"    // Center vertically (if needed)
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}> {/* Center content within the column */}
          {renderEventsCompanyFilter()}
        </Col>
      </Row>

      {/* Render events */}
      <Row gutter={[16, 16]}>
        {paginatedEvents.map((event, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={event.company}
              content={event.insight}
              link={event.url_link}
              linkText="View More"
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredData.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

const renderFunctionalityContent = () => {
  
  const paginatedFunctionality = functionalityFilteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );
  console.log('filteredData in renderFunctionality',filteredData);

  return (
    <>
       {/* Render the company filter dropdown */}
       <Row gutter={[16, 16]} justify="center" align="middle" style={{ marginBottom: "16px" }}>
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
          {renderFunctionalityCompanyFilter()}
        </Col>
      </Row>

      {/* Render functionality */}
      <Row gutter={[16, 16]}>
        {paginatedFunctionality.map((item, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomCard
              backgroundColor={index % 2 === 0 ? "#f0f8ff" : "#e6e6fa"}
              title={item.company}
              content={item.insight}
              link={item.url_link}
              linkText="View More"
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={functionalityFilteredData.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

const renderNews = () => {
  if (isNewsLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "flex-start", // Align to the top
          height: "100vh", // Full viewport height
          paddingTop: "20px", // Add some spacing from the top
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  const filteredNews = news.filter((item) => {
    let newsDate = moment(item.date, 'YYYY-MM-DD'); // Ensure all dates are in 'YYYY-MM-DD'

    // If "All" is selected or no company is selected, show all news items
    if (selectedCompany && selectedCompany !== "All" && item.company !== selectedCompany) {
      return false;
    }

    // Filter by date range if selected
    if (selectedDateRange) {
      const [startDate, endDate] = selectedDateRange;

      // Apply the filtering based on the stored formatted dates
      if (!newsDate.isBetween(moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD'), null, '[]')) {
        return false;
      }
    }

    return true;
  });

  const paginatedNews = filteredNews.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const uniqueCompanies = [...new Set(news.map((item) => item.company))];

  return (
    <>
      {/* Filters */}
      <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
        <Col span={12}>
          <Select
            placeholder="Filter by company"
            style={{ width: '100%' }}
            allowClear
            value={selectedCompany || "All"} // Default to "All" if no company is selected
            onChange={(value) => setSelectedCompany(value)}
          >
            <Option value="All">All</Option> {/* Add the "All" option */}
            {uniqueCompanies.map((company) => (
              <Option key={company} value={company}>
                {company}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <DatePicker.RangePicker
            onChange={(dates) => {
              if (dates && dates.length === 2) {
                const startDate = moment(dates[0].$d).format('YYYY-MM-DD');
                const endDate = moment(dates[1].$d).format('YYYY-MM-DD');
                setSelectedDateRange([startDate, endDate]);
              } else {
                setSelectedDateRange(null);
              }
            }}
            format={"YYYY-MM-DD"}  // Ensuring consistent display formatting
          />
        </Col>
      </Row>

      {/* News Cards */}
      <Row gutter={[16, 16]}>
        {paginatedNews.map((item, index) => {
          const newsDate = moment(item.date, moment.ISO_8601, true).format('YYYY-MM-DD'); // Format the date
          const backgroundColor = index % 2 === 0 ? "#f0f8ff" : "#e6e6fa"; // Alternating colors

          return (
            <Col span={24} key={index} style={{ marginBottom: "16px" }}>
              <NewsCard
                backgroundColor={backgroundColor}
                title={item.title}
                content={`${item.description} \nSource: ${item.source} • Company: ${item.company}`}
                link={item.link}
                linkText={"View More"}
                date={newsDate}  // Pass the formatted date to the NewsCard component
              />
            </Col>
          );
        })}
        <Pagination
          current={currentPage}
          pageSize={ITEMS_PER_PAGE}
          total={filteredNews.length}
          onChange={(page) => setCurrentPage(page)}
          style={{ marginTop: "16px", textAlign: "center" }}
        />
      </Row>
    </>
  );
};

// Utility function to convert plain text URLs into clickable links
const makeTextWithLinks = (text) => {
  if (!text) {
    return "";
  }
  
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: #007AFF;">${url}</a>`;
  });
};

// Helper function to convert to initcap (capitalize first letter of each word)
const toInitCap = (text) => {
  return text.replace(/\b\w/g, char => char.toUpperCase());
};

const renderLinkedinPosts = () => {
  return linkedinPaginatedData.map((post, index) => {
    // Alternate background colors
    const backgroundColor = index % 2 === 0 ? "#f7f9fc" : "#e6e9ef"; // Alternate light shades for iPhone look

    return (
      <Card
        key={index}
        style={{
          marginTop: '16px',  // Ensure spacing at the top
          marginBottom: '16px',  // Ensure spacing at the bottom
          maxWidth: '600px',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '20px',  // Softer rounded corners for iPhone look
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',  // Softer shadow for iPhone look
          padding: '16px',  // Add padding for cleaner look
          background: backgroundColor, // Alternating background colors
          color: '#333',  // Darker text for better readability
          fontFamily: `"SF Pro Display", "Helvetica Neue", Arial, sans-serif`,  // iPhone-style font
        }}
      >
        {/* Company name in init-cap and links */}
        <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: '8px' }}>
          <strong>{toInitCap(post.company)}</strong> • {post.time} •{" "}
          <a href={post.url} target="_blank" rel="noopener noreferrer" style={{ color: "#007AFF" }}>
            See Original Post
          </a>
        </div>

        {/* Render the post content with clickable links */}
        <div
          style={{ fontSize: "16px", marginBottom: '12px', lineHeight: '1.5em' }}
          dangerouslySetInnerHTML={{ __html: makeTextWithLinks(post.text) }}
        />

        {/* Image or video content if available */}
        {post.image_url && (
          <img
            src={post.image_url}
            alt="Post Image"
            style={{
              width: "100%", 
              maxHeight: '300px',
              objectFit: 'cover',
              marginTop: 16,
              borderRadius: '12px',
            }}
          />
        )}

        {post.video_url && (
          <video
            width="100%"
            controls
            style={{
              marginTop: 16,
              maxHeight: '300px',
              borderRadius: '12px',
            }}
          >
            <source src={post.video_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}

        {/* Reactions and comments */}
        <div style={{ marginTop: 16, fontSize: '14px', color: '#666' }}>
          {post.num_reactions && <span>{post.num_reactions} Reactions</span>}
          {post.num_comments && <span> • {post.num_comments} Comments</span>}
        </div>
      </Card>
    );
  });
};


// Function to render each video analysis item
const renderVideoAnalysis = () => {
  if (isVideoAnalysisLoading) {
    return <Spin size="large" style={{ marginTop: "20px" }} />;
  }

  return playlistsPaginatedData.map((playlist, index) => (
    <Card
      key={index}
      style={{
        marginBottom: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <div style={{ width: '100%', maxWidth: '900px' }}>
        <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px' }}>
          {/* Access title and url inside playlistInfo */}
          <a href={playlist.playlistInfo.url} target="_blank" rel="noopener noreferrer">
            {playlist.playlistInfo.title}
          </a> • {playlist.playlistInfo.company} • {playlist.playlistInfo.numberOfViews} Views
        </div>

        {/* Add the video upload timestamp */}
        <div style={{ fontSize: '14px', color: 'gray' }}>
          Uploaded on: {new Date(playlist.playlistInfo.videoUploadTimestamp).toLocaleDateString()}
        </div>

        {/* Video Iframe */}
        <div style={{ marginTop: 16 }}>
          {playlist.playlistInfo.url ? (
            <iframe
              width="100%"
              height="500"
              src={playlist.playlistInfo.url.replace('watch?v=', 'embed/')}
              title={playlist.playlistInfo.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          ) : (
            <p>No video available</p>
          )}
        </div>

        {/* Optional Transcript Button */}
        {playlist.transcript && (
          <div style={{ marginTop: 16 }}>
            <Button
              type="primary"
              onClick={() => {
                Modal.info({
                  title: 'Transcript',
                  content: <div>{playlist.transcript}</div>,
                });
              }}
            >
              Show Transcript
            </Button>
          </div>
        )}

        {/* Optional Video Analysis */}
        {playlist.analysis && (
          <div style={{ marginTop: 16, fontSize: '16px', fontStyle: 'italic' }}>
            <strong>Analysis:</strong> {playlist.analysis}
          </div>
        )}
      </div>
    </Card>
  ));
};


// renderEmployeeFeedbacks function
const renderEmployeeFeedbacks = () => {
  if (isEmployeeFeedbackLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "flex-start", // Align to the top
          height: "100vh", // Full viewport height
          paddingTop: "20px", // Add some spacing from the top
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  // Filter data based on selected filters
  const filteredFeedbacks = employeeFeedbacks.filter((feedback) => {
    const companyMatches =
      selectedCompany === null || feedback.company === selectedCompany;
    const feedbackTypeMatches =
      selectedFeedbackType === null || feedback.feedback_type === selectedFeedbackType;
    const ratingMatches =
      selectedRating === null || feedback.rating === selectedRating;
    return companyMatches && feedbackTypeMatches && ratingMatches;
  });

  // Paginated data for feedbacks
  const paginatedFeedbacks = filteredFeedbacks.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Filters */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Select
            placeholder="Filter by company"
            style={{ width: "100%" }}
            onChange={handleFeedbackCompanyChange}
            allowClear
          >
            <Option value={null}>All Companies</Option>
            {[...new Set(employeeFeedbacks.map((fb) => fb.company))].map(
              (company) => (
                <Option key={company} value={company}>
                  {company}
                </Option>
              )
            )}
          </Select>
        </Col>
        <Col span={8}>
          <Select
            placeholder="Filter by feedback type"
            style={{ width: "100%" }}
            onChange={handleFeedbackTypeChange}
            allowClear
          >
            <Option value={null}>All Types</Option>
            {[...new Set(employeeFeedbacks.map((fb) => fb.feedback_type))].map(
              (type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              )
            )}
          </Select>
        </Col>
        <Col span={8}>
          <Select
            placeholder="Filter by rating"
            style={{ width: "100%" }}
            onChange={handleFeedbackRatingChange}
            allowClear
          >
            <Option value={null}>All Ratings</Option>
            {[1, 2, 3, 4, 5].map((rating) => (
              <Option key={rating} value={rating}>
                {rating} Stars
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      {/* Feedback Cards */}
      <Row gutter={[16, 16]}>
        {paginatedFeedbacks.map((feedback, index) => {
          const backgroundColor = index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"; // Alternate colors

          return (
            <Col span={24} key={index} style={{ marginBottom: "16px" }}>
              <Card
                bordered={false}
                style={{
                  backgroundColor: backgroundColor,
                  color: "#333",
                  minHeight: "10px",
                  height: "auto", // Allow the card height to adjust dynamically
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bodyStyle={{ padding: "16px" }}
              >
                <div>
                  {/* Display company name in Title Case */}
                  <p style={{ fontWeight: "bold", marginBottom: "8px" }}>
                    {feedback.company.toLocaleUpperCase()}
                  </p>

                  {/* Review Title and Link */}
                  {feedback.review_title && feedback.review_link && (
                    <a
                      href={feedback.review_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ fontWeight: "bold", fontSize: "16px", marginBottom: "8px", display: "block" }}
                    >
                      {feedback.review_title}
                    </a>
                  )}

                  {/* Feedback Type and Rating */}
                  <p style={{ fontSize: "16px", marginBottom: "8px" }}>
                    <strong>{feedback.feedback_type}</strong> - {feedback.rating} Stars
                  </p>

                  {/* Employee Feedback */}
                  <p style={{ fontSize: "14px", marginBottom: "8px" }}>
                    {feedback.employee_feedback}
                  </p>

                  {/* Review Date */}
                  <p style={{ color: "#999", fontSize: "12px" }}>
                    {new Date(feedback.review_date).toLocaleDateString()}
                  </p>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredFeedbacks.length}
        onChange={(page) => {
          setCurrentPage(page);
        }}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

const renderReviewsAsCards = () => {
  if (isReviewsLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "flex-start", // Align to the top
          height: "100vh", // Full viewport height
          paddingTop: "20px", // Add some spacing from the top
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  // Filter data based on selected filters
  const filteredReviews = filteredRawReviews.filter((review) => {
    const companyMatches =
      selectedCompany === null || review.company === selectedCompany;
    const reviewTypeMatches =
      selectedReviewFeedbackType === null || review.reviewtype === selectedReviewFeedbackType;
    const ratingMatches =
      selectedReviewRating === null || review.rating === selectedReviewRating;
    return companyMatches && reviewTypeMatches && ratingMatches;
  });

  // Paginated data for reviews
  const paginatedReviews = filteredReviews.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Filters */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Select
            placeholder="Filter by company"
            style={{ width: "100%" }}
            onChange={handleReviewCompanyChange}
            allowClear
          >
            <Option value={null}>All Companies</Option>
            {[...new Set(filteredRawReviews.map((review) => review.company))].map(
              (company) => (
                <Option key={company} value={company}>
                  {company}
                </Option>
              )
            )}
          </Select>
        </Col>
        <Col span={8}>
          <Select
            placeholder="Filter by review type"
            style={{ width: "100%" }}
            onChange={handleReviewTypeChange}
            allowClear
          >
            <Option value={null}>All Types</Option>
            {[...new Set(filteredRawReviews.map((review) => review.reviewtype))].map(
              (type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              )
            )}
          </Select>
        </Col>
        <Col span={8}>
          <Select
            placeholder="Filter by rating"
            style={{ width: "100%" }}
            onChange={handleReviewRatingChange}
            allowClear
          >
            <Option value={null}>All Ratings</Option>
            {["1.0", "2.0", "3.0", "4.0", "5.0"].map((rating) => (
              <Option key={rating} value={rating}>
                {rating} Stars
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      {/* Review Cards */}
      <Row gutter={[16, 16]}>
        {paginatedReviews.map((review, index) => (
          <Col span={24} key={index}>
            <ReviewCard
              company={review.company}
              rating={review.rating}
              feedback={review.feedback}
              reviewDate={new Date(review.reviewdate).toLocaleDateString()}
              reviewType={review.reviewtype}
              username={review.username}
              url={review.url}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination for reviews */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredReviews.length}
        onChange={(page) => {
          const paginatedReviews = filteredReviews.slice(
            (page - 1) * ITEMS_PER_PAGE,
            page * ITEMS_PER_PAGE
          );
          setPaginatedRawReviews(paginatedReviews);
          setCurrentPage(page);
        }}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

const renderGoogleAds = () => {
  if (isAdsLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "flex-start", // Align to the top
          height: "100vh", // Full viewport height
          paddingTop: "20px", // Add some spacing from the top
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  return (
    <Row gutter={[16, 16]} justify="center">
      {paginatedData.map((ad, index) => {
        const isOddRow = Math.floor(index / 2) % 2 === 0; // Adjusted for 2 cards per row
        const backgroundColor = index % 2 === 0 ? (isOddRow ? "#1A1A2E" : "#0F3460") : isOddRow ? "#0F3460" : "#1A1A2E";

        return (
          <Col key={index} xs={24} sm={24} md={12} lg={12} style={{ display: "flex", justifyContent: "center" }}>
            <Card
              bordered={false}
              hoverable
              style={{
                flex: "1",
                backgroundColor: backgroundColor,
                color: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                fontFamily: "Montserrat, sans-serif",
              }}
              bodyStyle={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
              title={
                <span style={{ color: "#FFFFFF", fontSize: "14px", fontWeight: "bold" }}>
                  {ad.company} • Search Term: {ad.searchTerm} • Region: {ad.region} • Created At: {new Date(ad.createdAt).toLocaleDateString()}
                </span>
              }
            >
              <div style={{ position: "relative" }}>
                <img
                  src={ad.image}
                  alt="Ad Image"
                  style={{ width: "100%", borderRadius: "8px" }}
                />
                <span
                  role="img"
                  aria-label="zoom-in"
                  className="anticon anticon-zoom-in"
                  style={{
                    position: "absolute",
                    top: "8px",
                    left: "8px",
                    fontSize: "24px",
                    color: "#000000",  // Set color to black for better visibility
                  }}
                  onClick={() => handleZoom(ad.image)}
                >
                  <ZoomInOutlined />
                </span>
              </div>

              {ad.ads && ad.ads.map((item, adIndex) => (
                <Card
                  key={adIndex}
                  style={{ marginTop: "16px", backgroundColor: "#F0F0F0", color: "#000000" }}
                >
                  <div>
                    <p>
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.title}
                      </a>
                    </p>
                    <p>{item.description}</p>
                    <p><strong>Competitor:</strong> {item.competitor}</p>
                  </div>
                </Card>
              ))}
            </Card>
          </Col>
        );
      })}

      {/* Modal for zoomed image */}
      {zoomedImage && (
        <Modal
          visible={!!zoomedImage}
          footer={null}
          onCancel={() => setZoomedImage(null)} // Close modal on cancel
        >
          <img
            src={zoomedImage}
            alt="Zoomed"
            style={{ width: '100%', height: 'auto' }} // Ensure full width and proportional height
          />
        </Modal>
      )}
    </Row>
  );
};

// Helper function to format the timestamp to 'Month Year'
const formatTimestamp = (timestamp) => {
  if (!timestamp) return "Invalid Date";
  const year = timestamp.substring(0, 4);
  const month = timestamp.substring(4, 6);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const monthName = monthNames[parseInt(month, 10) - 1];
  return `${monthName} ${year}`;
};

// Formatting function for Key Takeaways content
const formatKeyTakeawayContent = (content, companyName) => {
  // Updated regex to match only "Section <number>" without following text
  const boldSectionRegex = /\b(Section \d+)\b/gi;          // Match only "Section <number>"
  const boldMarkersRegex = /\*\*(.*?)\*\*/g;               // Match text between ** markers
  const companyRegex = new RegExp(`\\b${companyName}\\b`, 'gi');  // Case-insensitive match for company name

  // First, replace company names in the content with <strong> tags
  let formattedContent = content.replace(companyRegex, (match) => `<strong>${match}</strong>`);

  // Apply bolding for only "Section <number>"
  formattedContent = formattedContent.replace(boldSectionRegex, (match) => `<strong>${match}</strong>`);

  // Apply bolding for text between ** markers
  formattedContent = formattedContent.replace(boldMarkersRegex, (match, p1) => `<strong>${p1}</strong>`);

  // Split the content by paragraphs and render as JSX
  return formattedContent.split(/\n\n/).map((paragraph, index) => (
    <p key={index} style={{ marginBottom: "8px" }} dangerouslySetInnerHTML={{ __html: paragraph }} />
  ));
};



// Helper function to replace **text** with <b>text</b>
const formatAnalysis = (analysis) => {
  if (typeof analysis !== 'string') {
    return ''; // Return an empty string if analysis is undefined, null, or not a string
  }

  // Replace ### with paragraph tags for better formatting
  let formattedAnalysis = analysis.split('###').join('</p><p>');

  // Replace **text** with <b>text</b> using string splitting and reassembly
  const parts = formattedAnalysis.split('**');
  let result = '';
  let isBold = false;

  parts.forEach((part) => {
    if (isBold) {
      result += `<b>${part}</b>`;
    } else {
      result += part;
    }
    isBold = !isBold; // Toggle between bold and non-bold
  });

  // Wrap the entire analysis in <p> tags to ensure a complete paragraph structure
  result = `<p>${result}</p>`;

  return result;
};


  
//const ITEMS_PER_PAGE = 6; // Change the number of screenshots per page if needed

const flattenScreenshots = (data) => {
  return data.reduce((acc, item) => {
    if (item.screenshots) {
      // Push all screenshots into the flattened array
      acc.push(...item.screenshots.map(screenshot => ({
        ...screenshot,
        company: item.company,
        analysis: item.summary?.analysis,
        createdAt: item.summary?.createdAt,
      })));
    }
    return acc;
  }, []);
};


useEffect(() => {
  if ((selectedMenu === 'Pricing' || selectedMenu === 'Homepage')  && filteredData && filteredData.length > 0) {
    // Flatten the screenshots data
    const allScreenshots = flattenScreenshots(filteredData);

    // Set paginated data to show 2 items per page for Pricing
    const paginated = allScreenshots.slice(0, 2);
    setPaginatedData(paginated);
    setCurrentPage(1); // Reset to the first page
  } else if (filteredData && filteredData.length > 0) {
    // For other pages, set paginated data as usual
    const paginated = filteredData.slice(0, ITEMS_PER_PAGE);
    setPaginatedData(paginated);
    setCurrentPage(1); // Reset to the first page
  }
}, [selectedMenu, filteredData]);


// Render toggle switch and content for each menu item
const renderMenuWithToggle = (menuName, renderContent) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
    {/* Toggle Label and Switch in a Row */}
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <label>Show Key Takeaways:</label>
      <Switch
        checked={showKeyTakeaways[menuName]}
        onChange={() => handleToggleChange(menuName)}
      />
    </div>

    {/* Content Row */}
    <div style={{ marginTop: '16px' }}>
      {showKeyTakeaways[menuName] ? renderKeyTakeaways() : renderContent()}
    </div>
  </div>
);

// Function for Battlecards content in the Battlecards menu
const renderBattlecardsContent = () => {
  // Filter Battlecards by "BattleCards - Data Categorization"
  const filteredBattlecardsContent = battlecards.filter(
    (card) => card.battlecard_name === "BattleCards - Data Categorization"
  );

  // Paginate the filtered content
  const paginatedBattlecards = filteredBattlecardsContent.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render Battlecards in the Key Takeaways layout */}
      <Row gutter={[16, 16]}>
        {paginatedBattlecards.map((card, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={card.company.toUpperCase()}
              content={formatKeyTakeawayContent(card.keytakeaway, card.company)}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredBattlecardsContent.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};


// Render Key Takeaways cards with company in uppercase
const renderKeyTakeaways = () => (
  <DashboardSummary battlecards={filteredBattlecards} />
);

// renderPricing function for rendering data
const renderPricing = () => {
  if (isPriceLoading) {
    return <Spin size="large" style={{ marginTop: "20px" }} />;
  }

  if (!paginatedData || paginatedData.length === 0) {
    return <div>No pricing data available.</div>;
  }

  // Apply company filter to filteredData
  const filteredPricingData = selectedCompany && selectedCompany !== 'All'
    ? filteredData.filter((item) => item.company === selectedCompany)
    : filteredData;

  // Handle pagination of filtered data
  const handlePageChange = (page) => {
    const allScreenshots = flattenScreenshots(filteredPricingData); // Filtered data
    const startIdx = (page - 1) * 2; // Show only 2 screenshots per page
    const endIdx = startIdx + 2;
    setPaginatedData(allScreenshots.slice(startIdx, endIdx)); // Update paginatedData on page change
    setCurrentPage(page); // Ensure current page is updated correctly
  };

  // Pagination will use the filtered pricing data
  const currentPaginatedData = paginatedData || [];

  return (
    <>
      {/* Render analysis as plain HTML without styling */}
      {currentPaginatedData.length > 0 && (
        <div style={{ marginBottom: "24px" }}>
          <h3>
            Price Summary for: {currentPaginatedData[0].company} • Analyzed at:{" "}
            {new Date(currentPaginatedData[0].createdAt).toLocaleDateString()}
          </h3>
          <div dangerouslySetInnerHTML={{ __html: currentPaginatedData[0].analysis || '' }} />
        </div>
      )}

      {/* Pricing Screenshots Cards */}
      <Row gutter={[16, 16]} justify="center">
        {currentPaginatedData.map((screenshot, idx) => (
          <Col key={idx} xs={24} sm={24} md={12} lg={12} style={{ display: "flex", justifyContent: "center" }}>
            <Card
              bordered={false}
              hoverable
              style={{
                backgroundColor: idx % 2 === 0 ? "#1A1A2E" : "#0F3460",
                color: "#FFFFFF",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                marginBottom: "16px"
              }}
              title={
                <span style={{ color: "#FFFFFF" }}>
                  Snapshot Time: {formatTimestamp(screenshot.timestamp) || 'Invalid Date'}
                </span>
              }
            >
              <img
                src={screenshot.image}
                alt={`Screenshot ${idx + 1}`}
                style={{ width: "100%", borderRadius: "8px" }}
              />
            </Card>
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={2}  // Show 2 screenshots per page
        total={flattenScreenshots(filteredPricingData).length}
        onChange={handlePageChange}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};


const renderHomepage = () => {
  // Show loading spinner if data is still being fetched
  if (isHomepageLoading) {
    return <Spin size="large" style={{ marginTop: "20px" }} />;
  }

  // Handle case when no data is available after loading completes
  if (!paginatedData || paginatedData.length === 0) {
    return <div>No homepage data available.</div>;
  }

  // Apply company filter to homepage data
  const filteredHomepageData = selectedCompany && selectedCompany !== 'All'
    ? filteredData.filter((item) => item.company === selectedCompany)
    : filteredData;

  // Flatten screenshots for pagination but keep analysis consistent
  const allScreenshots = flattenScreenshots(filteredHomepageData);
  const handlePageChange = (page) => {
    const startIdx = (page - 1) * 2; // Show only 2 screenshots per page
    const endIdx = startIdx + 2;
    setPaginatedData(allScreenshots.slice(startIdx, endIdx)); // Update paginatedData on page change
    setCurrentPage(page); // Ensure current page is updated correctly
  };

  // Always use filteredHomepageData[0] for the analysis card
  const analysisData = filteredHomepageData[0]?.summary?.analysis;

  return (
    <>
      {/* Full-width Homepage Summary Card */}
        {/* Render analysis as plain HTML without styling */}
        {paginatedData.length > 0 && (
        <div style={{ marginBottom: "24px" }}>
          <h3>
            Price Summary for: {paginatedData[0].company} • Analyzed at:{" "}
            {new Date(paginatedData[0].createdAt).toLocaleDateString()}
          </h3>
          <div dangerouslySetInnerHTML={{ __html: paginatedData[0].analysis || '' }} />
        </div>
      )}

      {/* Homepage Screenshots Cards */}
      <Row gutter={[16, 16]} justify="center">
        {paginatedData.map((screenshot, idx) => (
          <Col key={idx} xs={24} sm={24} md={12} lg={12} style={{ display: "flex", justifyContent: "center" }}>
            <Card
              bordered={false}
              hoverable
              style={{
                backgroundColor: idx % 2 === 0 ? "#1A1A2E" : "#0F3460",
                color: "#FFFFFF",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                marginBottom: "16px"
              }}
              title={
                <span style={{ color: "#FFFFFF" }}>
                  Snapshot Time: {formatTimestamp(screenshot.timestamp) || 'Invalid Date'}
                </span>
              }
            >
              <img
                src={screenshot.image}
                alt={`Screenshot ${idx + 1}`}
                style={{ width: "100%", borderRadius: "8px" }}
              />
            </Card>
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={2}  // Show 2 screenshots per page
        total={allScreenshots.length}
        onChange={handlePageChange}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

const renderJobs = () => {
  if (isJobsLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "100vh",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const paginatedJobs = paginatedData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
          {renderJobsCompanyFilter()}
        </Col>
      </Row>

      {/* Render jobs */}
      <Row gutter={[16, 16]}>
        {paginatedJobs.map((job, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomJobsCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={job.company.toUpperCase()}
              content={(
                <>
                  <div>{job.title}</div>
                  <div>{job.location}</div>
                  <a href={job.url} target="_blank" rel="noopener noreferrer">
                    <u>Job Post Link</u>
                  </a>
                </>
              )}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredData.length}
        onChange={(page) => {
          const paginatedData = filteredData.slice(
            (page - 1) * ITEMS_PER_PAGE,
            page * ITEMS_PER_PAGE
          );
          setPaginatedData(paginatedData);
          setCurrentPage(page);
        }}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};


const renderClientsContent = () => {
  if (isClientsLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "100vh",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const paginatedClients = filteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"  // Center horizontally
        align="middle"    // Center vertically (if needed)
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}> {/* Center content within the column */}
          {renderClientsCompanyFilter()}
        </Col>
      </Row>

      {/* Render the client cards */}
      <Row gutter={[16, 16]}>
        {paginatedClients.map((client, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomClientCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={client.company.toUpperCase()}
              content={(
                <>
                  <div>{client.insight}</div>
                  <div>{client.type}</div>
                  <a href={client.url_link} target="_blank" rel="noopener noreferrer">
                    <u>View Case Study</u>
                  </a>
                </>
              )}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredData.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};


const renderWeaknessesContent = () => {
  if (isWeaknessesLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "100vh",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const paginatedWeaknesses = filteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"  // Center horizontally
        align="middle"    // Center vertically (if needed)
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}> {/* Center content within the column */}
          {renderWeaknessesCompanyFilter()}
        </Col>
      </Row>

      {/* Render the weaknesses cards */}
      <Row gutter={[16, 16]}>
        {paginatedWeaknesses.map((weaknesses, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomWeaknessesCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={weaknesses.company.toUpperCase()}
              content={(
                <>
                  <div>{weaknesses.insight}</div>
                  <div>{weaknesses.type}</div>
                  <a href={weaknesses.url_link} target="_blank" rel="noopener noreferrer">
                    <u>View Details</u>
                  </a>
                </>
              )}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredData.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

const renderPartnersContent = () => {
  if (isPartnersLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "100vh",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const paginatedPartners = filteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"  // Center horizontally
        align="middle"    // Center vertically (if needed)
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}> {/* Center content within the column */}
          {renderPartnersCompanyFilter()}
        </Col>
      </Row>

      {/* Render the partners cards */}
      <Row gutter={[16, 16]}>
        {paginatedPartners.map((partners, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomPartnersCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={partners.company.toUpperCase()}
              content={(
                <>
                  <div>{partners.insight}</div>
                  <div>{partners.type}</div>
                  <a href={partners.url_link} target="_blank" rel="noopener noreferrer">
                    <u>View Details</u>
                  </a>
                </>
              )}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredData.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};


const renderIntegrationsContent = () => {
  if (isIntegrationsLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "100vh",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const paginatedIntegrations = filteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"  // Center horizontally
        align="middle"    // Center vertically (if needed)
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}> {/* Center content within the column */}
          {renderIntegrationsCompanyFilter()}
        </Col>
      </Row>

      {/* Render the integrations cards */}
      <Row gutter={[16, 16]}>
        {paginatedIntegrations.map((integrations, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomIntegrationsCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={integrations.company.toUpperCase()}
              content={(
                <>
                  <div>{integrations.insight}</div>
                  <div>{integrations.type}</div>
                  <a href={integrations.url_link} target="_blank" rel="noopener noreferrer">
                    <u>View Details</u>
                  </a>
                </>
              )}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredData.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

const renderCompetitorsContent = () => {
  if (isCompetitorsLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "100vh",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const paginatedCompetitors = filteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"  // Center horizontally
        align="middle"    // Center vertically (if needed)
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}> {/* Center content within the column */}
          {renderCompetitorsCompanyFilter()}
        </Col>
      </Row>

      {/* Render the competitors cards */}
      <Row gutter={[16, 16]}>
        {paginatedCompetitors.map((competitors, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomCompetitorsCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={competitors.company.toUpperCase()}
              content={(
                <>
                  <div>{competitors.insight}</div>
                  <div>{competitors.type}</div>
                  <a href={competitors.url_link} target="_blank" rel="noopener noreferrer">
                    <u>View Details</u>
                  </a>
                </>
              )}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredData.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};


const renderStrengthsContent = () => {
  if (isStrengthsLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "100vh",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const paginatedStrengths = strengthsFilteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"  // Center horizontally
        align="middle"    // Center vertically (if needed)
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}> {/* Center content within the column */}
          {renderStrengthsCompanyFilter()}
        </Col>
      </Row>

      {/* Render the strengths cards */}
      <Row gutter={[16, 16]}>
        {paginatedStrengths.map((strengths, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomStrengthsCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={strengths.company.toUpperCase()}
              content={(
                <>
                  <div>{strengths.insight}</div>
                  <div>{strengths.type}</div>
                  <a href={strengths.url_link} target="_blank" rel="noopener noreferrer">
                    <u>View Details</u>
                  </a>
                </>
              )}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={strengthsFilteredData.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};



const renderCompanyFilter = () => {
  return (
    <Select
      mode="single"
      placeholder="Filter by company"
      style={{ width: "20%", marginBottom: "16px" }}
      onChange={handleCompanyFilterChange}
      defaultValue={["All"]}  // Set default value to "All"
    >
      <Option value="All">All</Option> {/* Add "All" option */}
      {filteredData && Array.isArray(filteredData) && filteredData.length > 0 ? (
        [...new Set(filteredData.map((post) => post.company))].map(
          (company) => (
            <Option key={company} value={company}>
              {company}
            </Option>
          )
        )
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
  );
};

const renderLinkedinCompanyFilter = () => {
  if (isLinkedinLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "top", // Align to the middle vertically
          height: "100vh", // Full viewport height
          width: "100%", // Full viewport width
        }}
      >
        <Spin size="large" style={{ marginTop: "20px" }} />
      </div>
    );
  }
  return (
    <Select
      mode="single"
      placeholder="Filter by company"
      style={{ width: "20%", marginBottom: "16px" }}
      onChange={handleLinkedinCompanyFilterChange} // Call the new LinkedIn-specific handler
      defaultValue={["All"]}  // Set default value to "All"
    >
      <Option value="All">All</Option> {/* Add "All" option */}
      {linkedin && Array.isArray(linkedin) && linkedin.length > 0 ? (
        [...new Set(linkedin.map((post) => post.company))].map(
          (company) => (
            <Option key={company} value={company}>
              {toInitCap(company)} {/* Convert company names to InitCap */}
            </Option>
          )
        )
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
  );
};

const handlePricingCompanyFilterChange = (selectedCompanies) => {
  setFilteredCompanies(selectedCompanies);
  let newFilteredPricingData;

  if (selectedCompanies.includes("All")) {
    newFilteredPricingData = pricing; // Use the full pricing data
  } else {
    newFilteredPricingData = pricing.filter((item) =>
      selectedCompanies.includes(item.company)
    );
  }

  setFilteredData(newFilteredPricingData);
  setPaginatedData(newFilteredPricingData.slice(0, 2)); // Set the first page of data for pricing
};

const handleFunctionalityFilterChange = (selectedCompanies) => {
  setFilteredCompanies(selectedCompanies);
  let newFilteredData;

  if (selectedCompanies.includes("All")) {
    newFilteredData = functionality; // Use the full functionality data if "All" is selected
  } else {
    newFilteredData = functionality.filter((item) =>
      selectedCompanies.includes(item.company)
    );
  }
  setFunctionalityFilteredData(newFilteredData); // Update the filtered data state
  setFunctionalityPaginatedData(newFilteredData.slice(0, ITEMS_PER_PAGE)); // Set first page of paginated data
  setCurrentPage(1); // Reset to the first page
};

const handleClientsFilterChange = (selectedCompany) => {
  let newFilteredData;

  if (selectedCompany === "All") {
    newFilteredData = clients; // If "All" is selected, show all clients
  } else {
    newFilteredData = clients.filter((client) => client.company === selectedCompany);
  }

  setFilteredData(newFilteredData); // Update the filtered data based on the selection
  setCurrentPage(1); // Reset pagination to the first page after filtering
};

const handleWeaknessesFilterChange = (selectedCompany) => {
  let newFilteredData;

  if (selectedCompany === "All") {
    newFilteredData = weaknesses; // If "All" is selected, show all weaknesses
  } else {
    newFilteredData = weaknesses.filter((weaknesses) => weaknesses.company === selectedCompany);
  }

  setFilteredData(newFilteredData); // Update the filtered data based on the selection
  setCurrentPage(1); // Reset pagination to the first page after filtering
};

const handlePartnersFilterChange = (selectedCompany) => {
  let newFilteredData;

  if (selectedCompany === "All") {
    newFilteredData = partners; // If "All" is selected, show all partners
  } else {
    newFilteredData = partners.filter((partners) => partners.company === selectedCompany);
  }

  setFilteredData(newFilteredData); // Update the filtered data based on the selection
  setCurrentPage(1); // Reset pagination to the first page after filtering
};

const handleIntegrationsFilterChange = (selectedCompany) => {
  let newFilteredData;

  if (selectedCompany === "All") {
    newFilteredData = integrations; // If "All" is selected, show all integrations
  } else {
    newFilteredData = integrations.filter((integrations) => integrations.company === selectedCompany);
  }

  setFilteredData(newFilteredData); // Update the filtered data based on the selection
  setCurrentPage(1); // Reset pagination to the first page after filtering
};

const handleCompetitorsFilterChange = (selectedCompany) => {
  let newFilteredData;

  if (selectedCompany === "All") {
    newFilteredData = competitors; // If "All" is selected, show all integrations
  } else {
    newFilteredData = competitors.filter((competitors) => competitors.company === selectedCompany);
  }

  setFilteredData(newFilteredData); // Update the filtered data based on the selection
  setCurrentPage(1); // Reset pagination to the first page after filtering
};

const handleStrengthsFilterChange = (selectedCompany) => {
  let newFilteredData;

  if (selectedCompany === "All") {
    newFilteredData = strengths; // If "All" is selected, show all strengths
  } else {
    newFilteredData = strengths.filter((strengths) => strengths.company === selectedCompany);
  }

  setStrengthsFilteredData(newFilteredData); // Update the filtered data based on the selection
  setCurrentPage(1); // Reset pagination to the first page after filtering
};

const handleEventsFilterChange = (selectedCompanies) => {
  setFilteredCompanies(selectedCompanies);
  let newFilteredEvents;

  if (selectedCompanies.includes("All")) {
    newFilteredEvents = events; // Use the full events data if "All" is selected
  } else {
    newFilteredEvents = events.filter((item) =>
      selectedCompanies.includes(item.company)
    );
  }

  setEventsFilteredData(newFilteredEvents); // Update the filtered data state
  setEventsPaginatedData(newFilteredEvents.slice(0, ITEMS_PER_PAGE)); // Set first page of paginated data
  setCurrentPage(1); // Reset to the first page
};

const renderPricingCompanyFilter = () => {
  return (
    <Select
      mode="multiple"
      placeholder="Filter by company"
      style={{ width: "100%", marginBottom: "16px" }}
      onChange={handlePricingCompanyFilterChange}
      defaultValue={["All"]}
    >
      <Option value="All">All</Option>
      {pricing && Array.isArray(pricing) && pricing.length > 0 ? (
        [...new Set(pricing.map((item) => item.company))].map(
          (company) => (
            <Option key={company} value={company}>
              {company}
            </Option>
          )
        )
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
  );
};

const renderHomepageCompanyFilter = () => {
  return (
    <Select
      mode="multiple"
      placeholder="Filter by company"
      style={{ width: "100%", marginBottom: "16px" }}
      onChange={handleHomepageCompanyFilterChange}
      defaultValue={["All"]}
    >
      <Option value="All">All</Option>
      {homepageScreenshots && Array.isArray(homepageScreenshots) && homepageScreenshots.length > 0 ? (
        [...new Set(homepageScreenshots.map((item) => item.company))].map(
          (company) => (
            <Option key={company} value={company}>
              {company}
            </Option>
          )
        )
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
  );
};

const handleHomepageCompanyFilterChange = (selectedCompanies) => {
  if (selectedCompanies.includes("All")) {
    setFilteredData(homepageScreenshots); // Show all data if "All" is selected
  } else {
    const filteredData = homepageScreenshots.filter((item) =>
      selectedCompanies.includes(item.company)
    );
    setFilteredData(filteredData);

    // Set pagination based on the filtered data
    const paginatedData = filteredData.slice(0, ITEMS_PER_PAGE);
    setPaginatedData(paginatedData);
    setCurrentPage(1);
  }
};

const renderFunctionalityCompanyFilter = () => {
  if (isFunctionalityLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          height: "100vh",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <label style={{ marginRight: "8px" }}>Filter Company:</label>
      <Select
        mode="single"
        placeholder="Filter by company"
        style={{ width: "200px", marginBottom: "16px" }}
        onChange={handleFunctionalityFilterChange}
        defaultValue="All"
      >
        <Option value="All">All</Option>
        {functionality && functionality.length > 0 ? (
          [...new Set(functionality.map((item) => item.company))].map(
            (company) => (
              <Option key={company} value={company}>
                {toInitCap(company)}
              </Option>
            )
          )
        ) : (
          <Option disabled>No companies available</Option>
        )}
      </Select>
    </div>
  );
};

const renderJobsCompanyFilter = () => {
  return (
    <Select
      mode="single"
      placeholder="Filter by company"
      style={{ width: "20%", marginBottom: "16px" }}
      onChange={handleJobsFilterChange}
      defaultValue={["All"]}
    >
      <Option value="All">All</Option>
      {jobs && jobs.length > 0 ? (
        [...new Set(jobs.map((item) => item.company))].map((company) => (
          <Option key={company} value={company}>
            {company.toUpperCase()}
          </Option>
        ))
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
  );
};


const renderClientsCompanyFilter = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <label style={{ marginRight: "8px" }}>Filter Company:</label>
    <Select
      mode="single"
      placeholder="Filter by company"
      style={{ width: "20%", marginBottom: "16px", width:"200px" }}
      onChange={handleClientsFilterChange}
      defaultValue="All"
    >
      <Option value="All">All</Option>
      {clients && clients.length > 0 ? (
        [...new Set(clients.map((item) => item.company))].map((company) => (
          <Option key={company} value={company}>
            {company.toUpperCase()}
          </Option>
        ))
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
    </div>
  );
};


const renderWeaknessesCompanyFilter = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <label style={{ marginRight: "8px" }}>Filter Company:</label>
    <Select
      mode="single"
      placeholder="Filter by company"
      style={{ width: "20%", marginBottom: "16px",width:"200px" }}
      onChange={handleWeaknessesFilterChange}
      defaultValue="All"
    >
      <Option value="All">All</Option>
      {weaknesses && weaknesses.length > 0 ? (
        [...new Set(weaknesses.map((item) => item.company))].map((company) => (
          <Option key={company} value={company}>
            {company.toUpperCase()}
          </Option>
        ))
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
    </div>
  );
};

const renderPartnersCompanyFilter = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <label style={{ marginRight: "8px" }}>Filter Company:</label>
    <Select
      mode="single"
      placeholder="Filter by company"
      style={{ width: "20%", marginBottom: "16px",width:"200px" }}
      onChange={handlePartnersFilterChange}
      defaultValue="All"
    >
      <Option value="All">All</Option>
      {partners && partners.length > 0 ? (
        [...new Set(partners.map((item) => item.company))].map((company) => (
          <Option key={company} value={company}>
            {company.toUpperCase()}
          </Option>
        ))
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
    </div>
  );
};


// Adjusted company filter dropdown with increased width
const renderStrengthsCompanyFilter = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <label style={{ marginRight: "8px" }}>Filter Company:</label>
    <Select
      mode="single"
      placeholder="Select a company"
      style={{ width: "200px" }} // Set a larger width to make options readable
      onChange={handleStrengthsFilterChange}
      defaultValue="All"
    >
      <Option value="All">All</Option>
      {strengths && strengths.length > 0 ? (
        [...new Set(strengths.map((item) => item.company))].map((company) => (
          <Option key={company} value={company}>
            {company.toUpperCase()}
          </Option>
        ))
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
  </div>
);

const renderIntegrationsCompanyFilter = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <label style={{ marginRight: "8px" }}>Filter Company:</label>
    <Select
      mode="single"
      placeholder="Filter by company"
      style={{ width: "20%", marginBottom: "16px", width: "200px"}}
      onChange={handleIntegrationsFilterChange}
      defaultValue="All"
    >
      <Option value="All">All</Option>
      {integrations && integrations.length > 0 ? (
        [...new Set(integrations.map((item) => item.company))].map((company) => (
          <Option key={company} value={company}>
            {company.toUpperCase()}
          </Option>
        ))
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
    </div>
  );
};

const renderCompetitorsCompanyFilter = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <label style={{ marginRight: "8px" }}>Filter Company:</label>
    <Select
      mode="single"
      placeholder="Filter by company"
      style={{ width: "20%", marginBottom: "16px", width: "200px"}}
      onChange={handleCompetitorsFilterChange}
      defaultValue="All"
    >
      <Option value="All">All</Option>
      {competitors && competitors.length > 0 ? (
        [...new Set(competitors.map((item) => item.company))].map((company) => (
          <Option key={company} value={company}>
            {company.toUpperCase()}
          </Option>
        ))
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
    </div>
  );
};

const renderEventsCompanyFilter = () => {
  return (
  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <label style={{ marginRight: "8px" }}>Filter Company:</label>
    <Select
      mode="single"
      placeholder="Filter by company"
      style={{ width: "20%", marginBottom: "16px", width: "200px"}}
      onChange={handleEventsFilterChange}
      defaultValue={["All"]}
    >
      <Option value="All">All</Option>
      {events && events.length > 0 ? (
        [...new Set(events.map((item) => item.company))].map((company) => (
          <Option key={company} value={company}>
              {toInitCap(company)} {/* Convert company names to InitCap */}
          </Option>
        ))
      ) : (
        <Option disabled>No companies available</Option>
      )}
    </Select>
    </div>
  );
};
// Function for Strengths Key Takeaways content
const renderStrengthsToggleOn = () => {
  const paginatedKeyTakeaways = filteredBattlecards.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
          {renderStrengthsCompanyFilter()}
        </Col>
      </Row>

      {/* Render Key Takeaways cards */}
      <Row gutter={[16, 16]}>
        {paginatedKeyTakeaways.map((card, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomStrengthsCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={card.company.toUpperCase()}
              content={formatKeyTakeawayContent(card.keytakeaway)}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredBattlecards.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

// Function for Weaknesses Key Takeaways content
const renderWeaknessesToggleOn = () => {
  const paginatedKeyTakeaways = filteredBattlecards.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
          {renderWeaknessesCompanyFilter()}
        </Col>
      </Row>

      {/* Render Key Takeaways cards */}
      <Row gutter={[16, 16]}>
        {paginatedKeyTakeaways.map((card, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomWeaknessesCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={card.company.toUpperCase()}
              content={formatKeyTakeawayContent(card.keytakeaway)}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredBattlecards.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

// Function for Partners Key Takeaways content
const renderPartnersToggleOn = () => {
  const paginatedKeyTakeaways = filteredBattlecards.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
          {renderPartnersCompanyFilter()}
        </Col>
      </Row>

      {/* Render Key Takeaways cards */}
      <Row gutter={[16, 16]}>
        {paginatedKeyTakeaways.map((card, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomPartnersCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={card.company.toUpperCase()}
              content={formatKeyTakeawayContent(card.keytakeaway)}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredBattlecards.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

// Function for Integrations Key Takeaways content
const renderIntegrationsToggleOn = () => {
  const paginatedKeyTakeaways = filteredBattlecards.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
          {renderIntegrationsCompanyFilter()}
        </Col>
      </Row>

      {/* Render Key Takeaways cards */}
      <Row gutter={[16, 16]}>
        {paginatedKeyTakeaways.map((card, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomIntegrationsCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={card.company.toUpperCase()}
              content={formatKeyTakeawayContent(card.keytakeaway)}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredBattlecards.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

const renderCompetitorsToggleOn = () => {
  const paginatedKeyTakeaways = filteredBattlecards.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
          {renderCompetitorsCompanyFilter()}
        </Col>
      </Row>

      {/* Render Key Takeaways cards */}
      <Row gutter={[16, 16]}>
        {paginatedKeyTakeaways.map((card, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomCompetitorsCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={card.company.toUpperCase()}
              content={formatKeyTakeawayContent(card.keytakeaway)}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredBattlecards.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

// Function for Functionality Key Takeaways content
const renderFunctionalityToggleOn = () => {
  const paginatedKeyTakeaways = filteredBattlecards.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
       <Row gutter={[16, 16]} justify="center" align="middle" style={{ marginBottom: "16px" }}>
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
          {renderFunctionalityCompanyFilter()}
        </Col>
      </Row>

      {/* Render Key Takeaways cards */}
      <Row gutter={[16, 16]}>
        {paginatedKeyTakeaways.map((card, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={card.company.toUpperCase()}
              content={formatKeyTakeawayContent(card.keytakeaway)}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredBattlecards.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

// Function for Events Key Takeaways content
const renderEventsToggleOn = () => {
  const paginatedKeyTakeaways = filteredBattlecards.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
          {renderEventsCompanyFilter()}
        </Col>
      </Row>

      {/* Render Key Takeaways cards */}
      <Row gutter={[16, 16]}>
        {paginatedKeyTakeaways.map((card, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={card.company.toUpperCase()}
              content={formatKeyTakeawayContent(card.keytakeaway)}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredBattlecards.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

// Function for Clients Key Takeaways content
const renderClientsToggleOn = () => {
  const paginatedKeyTakeaways = filteredBattlecards.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <>
      {/* Render the company filter dropdown */}
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
          {renderClientsCompanyFilter()}
        </Col>
      </Row>

      {/* Render Key Takeaways cards */}
      <Row gutter={[16, 16]}>
        {paginatedKeyTakeaways.map((card, index) => (
          <Col span={24} key={index} style={{ marginBottom: "16px" }}>
            <CustomCard
              backgroundColor={index % 2 === 0 ? "#EAF4FE" : "#EDE7F6"}
              title={card.company.toUpperCase()}
              content={formatKeyTakeawayContent(card.keytakeaway)}
            />
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={ITEMS_PER_PAGE}
        total={filteredBattlecards.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: "16px", textAlign: "center" }}
      />
    </>
  );
};

// Function to show Strengths with Key Takeaways toggle on
const renderStrengthsWithToggle = () => renderMenuWithToggle("Strengths", renderStrengthsContent, renderStrengthsToggleOn);

// Function to show Weaknesses with Key Takeaways toggle on
const renderWeaknessesWithToggle = () => renderMenuWithToggle("Weaknesses", renderWeaknessesContent, renderWeaknessesToggleOn);


// Function to show Partners with Key Takeaways toggle on
const renderPartnersWithToggle = () => renderMenuWithToggle("Partners", renderPartnersContent, renderPartnersToggleOn);

// Function to show Integrations with Key Takeaways toggle on
const renderIntegrationsWithToggle = () => renderMenuWithToggle("Integrations", renderIntegrationsContent, renderIntegrationsToggleOn);

// Function to show Competitors with Key Takeaways toggle on
const renderCompetitorsWithToggle = () => renderMenuWithToggle("Competitors", renderCompetitorsContent, renderCompetitorsToggleOn);

// Function to show Functionality with Key Takeaways toggle on
const renderFunctionalityWithToggle = () => renderMenuWithToggle("Functionality", renderFunctionalityContent, renderFunctionalityToggleOn);

// Function to show Events with Key Takeaways toggle on
const renderEventsWithToggle = () => renderMenuWithToggle("Events", renderEventsContent, renderEventsToggleOn);

// Function to show Clients with Key Takeaways toggle on
const renderClientsWithToggle = () => renderMenuWithToggle("Clients", renderClientsContent, renderClientsToggleOn);

const renderStrengths = () => renderStrengthsWithToggle();

const renderWeaknesses = () => renderWeaknessesWithToggle();

const renderPartners = () => renderPartnersWithToggle();

const renderIntegrations = () => renderIntegrationsWithToggle();

const renderCompetitors = () => renderCompetitorsWithToggle();

const renderFunctionality = () => renderFunctionalityWithToggle();

const renderEvents = () => renderEventsWithToggle();

const renderClients = () => renderClientsWithToggle();


  const renderSection = (section) => {
    const cleanAnalysis = section.analysis.replace(/-\s*/g, "");
    const splitByCompany = (text, companies) => {
      let splitText = text;
      companies.forEach((company) => {
        const companyNamePattern = `\\.\\s*${company}`;
        const regex = new RegExp(companyNamePattern, "gi");
        splitText = splitText.replace(regex, `.|||${company}`);
      });
      return splitText.split(".|||");
    };

    const sentences = splitByCompany(cleanAnalysis, companyNames);
    const mainColor = sectionColors[section.tab] || "#ffffff";
    const isLight = isLightColor(mainColor);

    let subCards = [];
    let currentSubCard = "";

    sentences.forEach((sentence, index) => {
      let formattedSentence = sentence.trim();
      companyNames.forEach((company) => {
        const companyRegex = new RegExp(`\\b${company}\\b`, "gi");
        formattedSentence = formattedSentence.replace(
          companyRegex,
          `<b>${
            company.charAt(0).toUpperCase() + company.slice(1).toLowerCase()
          }</b>`
        );
      });

      const startsWithCompany = companyNames.some((company) =>
        formattedSentence.startsWith(
          `<b>${
            company.charAt(0).toUpperCase() + company.slice(1).toLowerCase()
          }</b>`
        )
      );

      if (startsWithCompany && currentSubCard) {
        subCards.push(currentSubCard);
        currentSubCard = formattedSentence;
      } else {
        currentSubCard += (currentSubCard ? ". " : "") + formattedSentence;
      }
    });

    if (currentSubCard) {
      subCards.push(currentSubCard);
    }

    return (
      <Card
        title={section.tab}
        bordered={false}
        style={{
          backgroundColor: mainColor,
          color: isLight ? "black" : "white",
        }}
        bodyStyle={{ color: isLight ? "black" : "white" }}
      >
        {subCards.map((subCard, index) => {
          const subCardColor = generateSubCardColor(index);
          const isSubCardLight = isLightColor(subCardColor);
          const textColor = isSubCardLight ? "black" : "white";

          return (
            <Card
              key={index}
              bordered={false}
              style={{
                backgroundColor: subCardColor,
                marginBottom: "8px",
                color: textColor,
              }}
              bodyStyle={{ color: textColor }}
            >
              <span dangerouslySetInnerHTML={{ __html: subCard }} />
            </Card>
          );
        })}
      </Card>
    );
  };

const renderSlide = (companyBattlecards) => {
  return (
    <div key={companyBattlecards[0].company}>
      <h2>{companyBattlecards[0].company}</h2>
      <Row gutter={[16, 16]}>
        {companyBattlecards.map((battlecard, index) => (
          <Col span={8} key={battlecard._id} style={{ marginBottom: '24px' }}>
            <Card
              bordered={false}
              style={{
                backgroundColor: '#f0f8ff',
                color: '#333333',
                minHeight: '200px',
                fontSize: '16px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              bodyStyle={{ padding: '20px' }}
            >
              <div style={{ textAlign: 'left' }}>
                <p align ="center"><strong>{battlecard.battlecard_name}</strong></p>
                <p>{battlecard.keytakeaway}</p>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

  const formatAIResponse = (response) => {
    // Additional: Regex to replace **text** with <b>text</b>
    const formattedResponse = response.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");

    // Original: Split response by lines and apply formatting logic
    const finalResponse = formattedResponse
      .split("\n")
      .filter((text) => text.trim() !== "")
      .map((text, index) => {
        let formattedText = text.replace(/(\d+)/g, "<b>$1</b>"); // Keep existing logic to bold numbers
        companyNames.forEach((company) => {
          const companyRegex = new RegExp(`\\b${company}\\b`, "gi");
          formattedText = formattedText.replace(
            companyRegex,
            `<b>${
              company.charAt(0).toUpperCase() + company.slice(1).toLowerCase()
            }</b>`
          );
        });

        // Keep original logic to wrap text with paragraphs or list items
        if (text.includes(":")) {
          return `<p><b>${formattedText.replace(/^-\s*/, "")}</b></p>`;
        }
        return `<li>${formattedText.replace(/^-\s*/, "")}</li>`;
      })
      .join("");

    // Keep original logic to wrap final response in a list
    return `<ul>${finalResponse}</ul>`;
  };

  const handleAIAnalysis = async () => {
    try {
      setIsLoading(true);

      console.log("AI Analysis triggered for sheet:", selectedMenu);

      let response;
      if (
        [
          "Employee Feedback",
          "Video Analysis",
          "Events",
          "Functionality",
          "LinkedIn",
          "News",
          "General Information",
        ].includes(selectedMenu)
      ) {
        const dataToSend = filteredData; // Send full data directly

        response = await axios.post(`${BASE_URL}/analyze-data`, {
          prompt: `Provide insights and key takeaways for the ${selectedMenu} data.`,
          dataType: selectedMenu.toLowerCase(),
          data: dataToSend,
        });
      }  else {
        response = await axios.post(`${BASE_URL}/ask-excel`, {
          prompt: `You are a management consultant who wants to keep the CEO abreast of events in his industry. You will review the given data provided for each element you will give the salient points of the post and also confirm what the key takeaways are likely to be and possible people who also need to be informed of this.`,
          tabName: menuToSheetMapping[selectedMenu],
          filteredData:
            filteredData.length > 0
              ? filteredData
              : data[menuToSheetMapping[selectedMenu]],
        });
      }

      console.log("AI Response received:", response.data.content);

      // Format the AI response for display in the modal
      const formattedResponse = formatAIResponse(response.data.content);
      setAIResponse(formattedResponse);

      setIsLoading(false);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error analyzing with AI:", error);
      setIsLoading(false);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getColumns = () => {
  if (!filteredData || filteredData.length === 0) {
    return [];
  }

  const columns = Object.keys(filteredData[0])
    .filter((key) => {
      // Exclude "IsProcessed" and "Hash Company" for "General Information"
      if (selectedMenu === "General Information") {
        const lowerCaseKey = key.toLowerCase();
        console.log('lowerCaseKey:',lowerCaseKey);
        return lowerCaseKey !== "isprocessed" && lowerCaseKey !== "hash__id" && lowerCaseKey !== "hash_company";      }
      return true;
    })
    .map((key) => {
      const isNumeric = typeof filteredData[0][key] === "number";

      if (selectedMenu === "News") {
        if (key === "Title") {
          return {
            title: key,
            dataIndex: key,
            key: key,
            sorter: (a, b) => a[key].localeCompare(b[key]),
            render: (text, record) => (
              <a href={record.Link} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
            ),
          };
        } else if (key === "Date") {
          return {
            title: key,
            dataIndex: key,
            key: key,
            sorter: (a, b) => new Date(a[key]) - new Date(b[key]),
            render: (text) => {
              if (isNaN(text)) {
                return text;
              } else {
                const date = new Date((text - (25567 + 1)) * 86400 * 1000);
                return date.toLocaleDateString();
              }
            },
          };
        } else if (key === "Thumbnail" || key === "Link") {
          return null;
        }
      }

      if (selectedMenu === "Employee Feedback") {
        if (key === "Review Title") {
          return {
            title: key,
            dataIndex: key,
            key: key,
            sorter: (a, b) => a[key].localeCompare(b[key]),
            render: (text, record) => (
              <a
                href={record["Review Link"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {text}
              </a>
            ),
          };
        } else if (key === "Review Date") {
          return {
            title: key,
            dataIndex: key,
            key: key,
            sorter: (a, b) => new Date(a[key]) - new Date(b[key]),
            render: (text) => {
              if (isNaN(text)) {
                return text;
              } else {
                const date = new Date((text - (25567 + 1)) * 86400 * 1000);
                return date.toLocaleDateString();
              }
            },
          };
        } else if (key === "Review Link") {
          return null;
        }
      }

      if (selectedMenu === "Events") {
        if (key === "Event") {
          return {
            title: key,
            dataIndex: key,
            key: key,
            sorter: (a, b) => a[key].localeCompare(b[key]),
            render: (text, record) =>
              record.Link && record.Link !== "Not specified" ? (
                <a
                  href={record.Link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text}
                </a>
              ) : (
                text
              ),
          };
        } else if (key === "Date") {
          return {
            title: key,
            dataIndex: key,
            key: key,
            sorter: (a, b) => new Date(a[key]) - new Date(b[key]),
            render: (text) => {
              if (text === "Not specified" || isNaN(text)) {
                return text;
              } else {
                const date = new Date((text - (25567 + 1)) * 86400 * 1000);
                return date.toLocaleDateString();
              }
            },
          };
        } else if (key === "Link") {
          return null;
        }
      }

      if (selectedMenu === "Clients") {
        if (key === "company") {
          return {
            title: "Company",
            dataIndex: "company",
            key: "company",
            sorter: (a, b) => a.company.localeCompare(b.company),
          };
        }

        if (key === "url_link") {
          return {
            title: "Case Study Link",
            dataIndex: "url_link",
            key: "url_link",
            render: (text) =>
              text && text !== "Not specified" ? (
                <a href={text} target="_blank" rel="noopener noreferrer">
                  View Case Study
                </a>
              ) : (
                "No Link"
              ),
          };
        }
      }

      if (selectedMenu === "Weaknesses") {
        if (key === "company") {
          return {
            title: "Company",
            dataIndex: "company",
            key: "company",
            sorter: (a, b) => a.company.localeCompare(b.company),
          };
        }

        if (key === "url_link") {
          return {
            title: "See Details",
            dataIndex: "url_link",
            key: "url_link",
            render: (text) =>
              text && text !== "Not specified" ? (
                <a href={text} target="_blank" rel="noopener noreferrer">
                  View Case Study
                </a>
              ) : (
                "No Link"
              ),
          };
        }
      }
      if (selectedMenu === "Partners") {
        if (key === "company") {
          return {
            title: "Company",
            dataIndex: "company",
            key: "company",
            sorter: (a, b) => a.company.localeCompare(b.company),
          };
        }

        if (key === "url_link") {
          return {
            title: "See Details",
            dataIndex: "url_link",
            key: "url_link",
            render: (text) =>
              text && text !== "Not specified" ? (
                <a href={text} target="_blank" rel="noopener noreferrer">
                  View Case Study
                </a>
              ) : (
                "No Link"
              ),
          };
        }
      }

      if (selectedMenu === "Strengths") {
        if (key === "company") {
          return {
            title: "Company",
            dataIndex: "company",
            key: "company",
            sorter: (a, b) => a.company.localeCompare(b.company),
          };
        }

        if (key === "url_link") {
          return {
            title: "See Details",
            dataIndex: "url_link",
            key: "url_link",
            render: (text) =>
              text && text !== "Not specified" ? (
                <a href={text} target="_blank" rel="noopener noreferrer">
                  View Case Study
                </a>
              ) : (
                "No Link"
              ),
          };
        }
      }

      if (selectedMenu === "Integrations") {
        if (key === "company") {
          return {
            title: "Company",
            dataIndex: "company",
            key: "company",
            sorter: (a, b) => a.company.localeCompare(b.company),
          };
        }

        if (key === "url_link") {
          return {
            title: "See Details",
            dataIndex: "url_link",
            key: "url_link",
            render: (text) =>
              text && text !== "Not specified" ? (
                <a href={text} target="_blank" rel="noopener noreferrer">
                  View Case Study
                </a>
              ) : (
                "No Link"
              ),
          };
        }
      }

      if (selectedMenu === "Competitors") {
        if (key === "company") {
          return {
            title: "Company",
            dataIndex: "company",
            key: "company",
            sorter: (a, b) => a.company.localeCompare(b.company),
          };
        }

        if (key === "url_link") {
          return {
            title: "See Details",
            dataIndex: "url_link",
            key: "url_link",
            render: (text) =>
              text && text !== "Not specified" ? (
                <a href={text} target="_blank" rel="noopener noreferrer">
                  View Case Study
                </a>
              ) : (
                "No Link"
              ),
          };
        }
      }

      // Handle General Information Tab Formatting
      if (selectedMenu === "General Information") {
        
        if (key === "_id" || key === "customers") {
          return null;
        }

        const formattedKey = key
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase());

        let renderFunction = (text) => text;
        if (key === "annual_recurring_revenue") {
          renderFunction = (text) =>
            `$ ${text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        } else if (key === "number_of_linkedin_followers") {
          renderFunction = (text) =>
            text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else if (isNumeric && key !== "year_founded") {
          renderFunction = (text) =>
            text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return {
          title: formattedKey,
          dataIndex: key,
          key: key,
          sorter: (a, b) =>
            isNumeric ? a[key] - b[key] : a[key].localeCompare(b[key]),
          render: renderFunction,
        };
      }

      return {
        title: key,
        dataIndex: key,
        key: key,
        sorter: (a, b) =>
          isNumeric ? a[key] - b[key] : a[key].localeCompare(b[key]),
        filters: [...new Set(filteredData.map((item) => item[key]))].map(
          (value) => ({
            text: value,
            value: value,
          })
        ),
        onFilter: (value, record) => record[key] === value,
        render: (text) => (isNumeric ? formatNumber(text) : text),
      };
    })
    .filter((column) => column !== null);

  const companyIndex = columns.findIndex((col) => col.dataIndex === "Company");
  if (companyIndex > 0) {
    const companyColumn = columns.splice(companyIndex, 1)[0];
    columns.unshift(companyColumn);
  }

  return columns;
};

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        style={{ backgroundColor: "#001529", paddingTop: "64px" }}
      >
        <Menu
          theme="dark"
          mode="inline"
          onSelect={handleMenuSelection}
          defaultSelectedKeys={["Dashboard Summary"]}
        >
          <Menu.Item key="Dashboard Summary" icon={<DashboardOutlined />}>
            Dashboard Summary
          </Menu.Item>
          <Menu.Item key="General Information" icon={<TeamOutlined />}>
            General Information
          </Menu.Item>
          <Menu.Item key="News" icon={<BarChartOutlined />}>
            News
          </Menu.Item>
          <Menu.Item key="Linkedin" icon={<LinkedinOutlined />}>
            Linkedin
          </Menu.Item>         
          <Menu.Item key="Video Analysis" icon={<VideoCameraOutlined />}>
            Video Analysis
          </Menu.Item>
          <Menu.Item key="Employee Feedback" icon={<SmileOutlined />}>
            Employee Feedback
          </Menu.Item>
          <Menu.Item key="Reviews" icon={<PieChartOutlined />}>
            Reviews
          </Menu.Item>        
          <Menu.Item key="Google Ads" icon={<DollarOutlined />}>
            Google Ads
          </Menu.Item>
          <Menu.Item key="Price Changes" icon={<DollarOutlined />}>
            Price Changes
          </Menu.Item>
          <Menu.Item key="Pricing" icon={<FundOutlined />}>
            Pricing
          </Menu.Item>
          <Menu.Item key="Homepage" icon={<FundOutlined />}>
            Homepage
          </Menu.Item>
          <Menu.Item key="Jobs" icon={<SolutionOutlined />}>
            Jobs
          </Menu.Item>  
          <Menu.Item key="Strengths" icon={<RiseOutlined />}>
            Strengths
          </Menu.Item>
          <Menu.Item key="Weaknesses" icon={<FallOutlined />}>
            Weaknesses
          </Menu.Item>
          <Menu.Item key="Integrations" icon={<ApiOutlined />}>
            Integrations
          </Menu.Item>
          <Menu.Item key="Competitors" icon={<ApiOutlined />}>
            Competitors
          </Menu.Item>
          <Menu.Item key="Functionality" icon={<BarChartOutlined />}>
            Functionality
          </Menu.Item>
          <Menu.Item key="Events" icon={<CalendarOutlined />}>
            Events
          </Menu.Item>
          <Menu.Item key="Clients" icon={<UserOutlined />}>
            Clients
          </Menu.Item>    
          <Menu.Item key="Partners" icon={<FallOutlined />}>
            Partners
          </Menu.Item> 
          <Menu.Item key="Ecosystem" icon={<DollarOutlined />}>
            Ecosystem
          </Menu.Item>  
          <Menu.Item key="Ask AI" icon={<RobotOutlined />}>
            Ask AI
          </Menu.Item>
          <Menu.Item key="Battlecards" icon={<DashboardOutlined />}>
            Battlecards
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout>
        <Header
          className="site-layout-background"
          style={{
            padding: "0 16px",
            display: "flex",
            alignItems: "center",
            color: "white",
          }}
        >
          <img
            src={logo}
            alt="BattleCards Logo"
            style={{ maxHeight: "32px", marginRight: "16px" }}
          />
          <h1 style={{ color: "white", margin: 0 }}>
            BattleCards - Competitor Analysis Platform - Prototype
          </h1>
          {selectedMenu !== "Ask AI" && (
            <Button
              type="primary"
              icon={<RobotOutlined />}
              style={{ marginLeft: "16px" }}
              onClick={handleAIAnalysis}
            >
              Analyze with AI
            </Button>
          )}
        </Header>

    <Content style={{ margin: "16px", position: "relative" }}>
  {isLoading && (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <Spin size="large" />
    </div>
  )}

    {selectedMenu === "Ask AI" ? (
      <div style={{ display: selectedMenu === "Ask AI" ? "block" : "none" }}>
    <AskAI customer_ObjectId={customer_ObjectId} />
  </div>
    ) : selectedMenu === "Video Analysis" ? (
      <>
        <Row className="video-analysis-row" gutter={[16, 16]} justify="center">
          {renderVideoAnalysis()}
        </Row>
        <Pagination
  current={currentPage}
  total={totalPlaylists}
  pageSize={ITEMS_PER_PAGE}
  onChange={handleVideoAnalysisPageChange} // Only updates the frontend pagination, no server call
  style={{ marginTop: 16, textAlign: "center" }}
/>
    </>
  ) : selectedMenu === "Pricing" ? (
   <>
  {renderPricingCompanyFilter()} 
  {renderPricing()} {/* Render the Pricing Data */}
  
</>
  ) : selectedMenu === "Homepage" ? (
    <>
   {renderHomepageCompanyFilter()} 
   {renderHomepage()} {/* Render the homepage Data */}
   
 </>
   ) : selectedMenu === "Employee Feedback" ? (
    <Row gutter={[16, 16]}>{renderEmployeeFeedbacks()}</Row>
  ) : selectedMenu === "Google Ads" ? (
    <>
  {/* Zoom Modal */}
  {zoomedImage && (
    <Modal
      visible={!!zoomedImage}
      footer={null}
      onCancel={() => setZoomedImage(null)} // Close the modal when canceling
    >
      <img
        src={zoomedImage}
        alt="Zoomed"
        style={{ width: "100%" }} // Image fits modal width
      />
    </Modal>
  )}

  {/* Render Google Ads */}
  {renderGoogleAds()}

  {/* Pagination */}
  <Pagination
    current={currentPage}
    pageSize={ITEMS_PER_PAGE}
    total={filteredData.length}
    onChange={(page) => {
      const paginatedData = filteredData.slice(
        (page - 1) * ITEMS_PER_PAGE,
        page * ITEMS_PER_PAGE
      );
      setPaginatedData(paginatedData);
      setCurrentPage(page);
    }}
    style={{ marginTop: "16px", textAlign: "center" }}
  />
</>
    
  ) : selectedMenu === "Clients" ? (
    <>
      {renderClients()}  
    </>
  ) : selectedMenu === "Weaknesses" ? (
    <>
      {renderWeaknesses()}  
    </>
  ) : selectedMenu === "Partners" ? (
    <>
      {renderPartners()}  
    </>
  ) : selectedMenu === "Strengths" ? (
    <>
      {renderStrengths()}  
    </>
  ) : selectedMenu === "Integrations" ? (
    <>
      {renderIntegrations()}  
    </>
  )  : selectedMenu === "Competitors" ? (
    <>
      {renderCompetitors()}  
    </>
  ) : selectedMenu === "Jobs" ? (
    <>
      {renderJobs()}
      {filteredData && (
        <Pagination
          current={currentPage}
          pageSize={ITEMS_PER_PAGE}
          total={filteredData.length}
          onChange={(page) => {
            const paginatedData = filteredData.slice(
              (page - 1) * ITEMS_PER_PAGE,
              page * ITEMS_PER_PAGE
            );
            setPaginatedData(paginatedData);
            setCurrentPage(page);
          }}
          style={{ marginTop: "16px", textAlign: "center" }}
        />
      )}
    </>
  ) :selectedMenu === "News" ? (
    <Row gutter={[16, 16]}>
      {renderNews()}
      
    </Row>
  ) : selectedMenu === "Dashboard Summary" ? (
    isBattlecardsLoading ? (  // Check if battlecards are still loading
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "flex-start", // Align to the top
          height: "100vh", // Full viewport height
          paddingTop: "20px", // Add some spacing from the top
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    ) : (
      <DashboardSummary battlecards={filteredBattlecards} />
    )
  ) : selectedMenu === "Battlecards" ? (
    <>
    {renderBattlecardsContent()}  
    </>
  ) : selectedMenu === "Price Changes" ? (
    
    isPriceChangesLoading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "flex-start", // Align to the top
          height: "100vh", // Full viewport height
          paddingTop: "20px", // Add some spacing from the top
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    ) : (
      <PriceChanges data={priceChangesData} />
    )
  )  : selectedMenu === "Ecosystem" ? (
    
    isAssociationsLoading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "flex-start", // Align to the top
          height: "100vh", // Full viewport height
          paddingTop: "20px", // Add some spacing from the top
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    ) : (
      <Associations data={associationsData} setRefreshAssociations={setRefreshAssociations}/>
    )
  ) : selectedMenu === "Linkedin" ? (
    <div>
      <Pagination
        style={{ marginBottom: 16 }}
        current={current}
        total={linkedinFilteredData ? linkedinFilteredData.length : 0}
        pageSize={5}
        onChange={(page, pageSize) => {
          const linkedinPaginatedData = linkedinFilteredData.slice(
            (page - 1) * pageSize,
            page * pageSize
          );
          setLinkedinPaginatedData(linkedinPaginatedData);
          setCurrent(page);
        }}
      />
      {renderLinkedinCompanyFilter()}
      {renderLinkedinPosts()}
      <Pagination
        style={{ marginTop: 16 }}
        current={current}
        total={linkedinFilteredData ? linkedinFilteredData.length : 0}
        pageSize={5}
        onChange={(page, pageSize) => {
          const linkedinPaginatedData = linkedinFilteredData.slice(
            (page - 1) * pageSize,
            page * pageSize
          );
          setLinkedinPaginatedData(linkedinPaginatedData);
          setCurrent(page);
        }}
      />
    </div>
  ) : selectedMenu === "Reviews" ? (
    <>
      {renderReviewsAsCards()}

    </>
  ) : selectedMenu === "Events" ? (
    renderEvents()  // Remove the extra <Row> wrapping for Events
  ) :  selectedMenu === "Functionality" ? (
  renderFunctionality()  // Remove the extra <Row> wrapping
  ) : selectedMenu === "General Information" ? (
    isGeneralInformationsLoading ? (  // Check if General Information is loading
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "flex-start", // Align to the top
          height: "100vh", // Full viewport height
          paddingTop: "20px", // Add some spacing from the top
          width: "100%",
        }}
      >
        <Spin size="large" />
      </div>
    ) : (
      <Table
        dataSource={filteredData}
        columns={getColumns()} // Dynamic columns based on data
        rowKey={(record, index) => index}
        onChange={handleTableChange}
      />
    )
) : (
    filteredData && (
      <Table
        dataSource={filteredData}
        columns={getColumns()}
        rowKey={(record, index) => index}
        onChange={handleTableChange}
      />
    )
)}
</Content>

        <Modal
          title={
            <>
              <RobotOutlined style={{ marginRight: 8 }} />
              AI Analysis
            </>
          }
          visible={isModalVisible}
          footer={[
            <Button
              key="copy"
              onClick={() => {
                navigator.clipboard.writeText(aiResponse);
                message.success("AI Analysis copied to clipboard");
              }}
            >
              Copy
            </Button>,
            <Button key="close" type="primary" onClick={handleCancel}>
              Close
            </Button>,
          ]}
          onCancel={handleCancel}
          width={800}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <div
              className="modal-paragraph"
              dangerouslySetInnerHTML={{ __html: aiResponse }}
            />
          )}
        </Modal>
      </Layout>
    </Layout>
  );
};

export default Dashboard;