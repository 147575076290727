import React from "react";
import AssociationsCart from "./AssociationsCart";

const Associations = ({ data,setRefreshAssociations }) => {
  // If data is undefined, return a fallback
  if (!data) {
    return <div>Loading data...</div>;
  }

  // Handle the case where there is no data
  if (data.length === 0) {
    return <div>Ecosystem data not available.</div>;
  }

  return (
    <div>
      <h1>Ecosystem</h1>
      <AssociationsCart
  data={data}
  setRefreshAssociations={setRefreshAssociations}
/>
    </div>
  );
};

export default Associations;