import React, { useState } from 'react';
import { Card, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const DashboardSummary = ({ battlecards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Navigate to the next chart
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % battlecards.length);
  };

  // Navigate to the previous chart
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? battlecards.length - 1 : prevIndex - 1
    );
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {battlecards.length > 0 && (
        <Card
          bordered={false}
          style={{
            margin: '0 auto',
            transition: 'all 0.3s ease-in-out',
            width: '100%',
            padding: '20px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <Button
            type="text"
            icon={<LeftOutlined />}
            onClick={handlePrevious}
            style={{
              position: 'absolute',
              top: '10px',
              left: '10px',
              zIndex: 2,
              backgroundColor: '#ffffff',
              borderRadius: '50%',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          />

          <div
            key={currentIndex}
            style={{
              width: '100%',
              height: 'auto',
              overflow: 'hidden',
            }}
          >
            <div
              style={{ width: '100%', overflowX: 'auto', paddingBottom: '20px' }}
              dangerouslySetInnerHTML={{
                __html: battlecards[currentIndex].keytakeaway.replace(
                  /<table/g,
                  "<table style='width: 100%; border-collapse: collapse;'"
                ),
              }}
            />
          </div>

          <Button
            type="text"
            icon={<RightOutlined />}
            onClick={handleNext}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 2,
              backgroundColor: '#ffffff',
              borderRadius: '50%',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          />
        </Card>
      )}
    </div>
  );
};

export default DashboardSummary;