import React, { useState, useMemo } from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import chroma from "chroma-js"; // For generating dynamic colors
import { Select } from "antd"; // Ant Design for multi-select dropdown
const { Option } = Select;

const PricingChart = ({ data }) => {
  // State to manage selected company-plans
  const [selectedPlans, setSelectedPlans] = useState([]);

  // Group data by company and plan for dropdown and filtering
  const groupedData = useMemo(() => {
    const grouped = {};
    if (!data) return {};

    data.forEach((item) => {
      const company = item.company;
      const plan = item.plan;

      if (!grouped[company]) {
        grouped[company] = {};
      }

      if (!grouped[company][plan]) {
        grouped[company][plan] = [];
      }

      grouped[company][plan].push({
        timestamp: new Date(item.timestamp).toLocaleDateString(), // Format date
        price: parseFloat(item.plan_usd_value), // Price for the chart
        description: item.plan_description, // Add description for tooltips
      });
    });

    return grouped;
  }, [data]);

  // Generate dynamic colors for companies and plans
  const companyColors = useMemo(() => {
    const companies = Object.keys(groupedData);
    return companies.reduce((acc, company, index) => {
      acc[company] = chroma
        .scale(["#8884d8", "#82ca9d", "#ffc658"])(index / companies.length)
        .hex();
      return acc;
    }, {});
  }, [groupedData]);

  // Prepare options for the multi-select dropdown
  const options = useMemo(() => {
    const opts = [];
    Object.entries(groupedData).forEach(([company, plans]) => {
      Object.keys(plans).forEach((plan) => {
        opts.push({
          label: `${company} - ${plan}`, // Show company and plan
          value: `${company}::${plan}`, // Unique identifier for selection
        });
      });
    });
    return opts;
  }, [groupedData]);

  // Filter the data based on selected company-plans
  const filteredData = useMemo(() => {
    if (selectedPlans.length === 0) return []; // No selections
    const filtered = [];
    selectedPlans.forEach((selected) => {
      const [company, plan] = selected.split("::"); // Split the identifier
      if (groupedData[company] && groupedData[company][plan]) {
        filtered.push({
          company,
          plan,
          data: groupedData[company][plan],
        });
      }
    });
    return filtered;
  }, [selectedPlans, groupedData]);

  // Calculate min and max for the Y-axis dynamically
  const yAxisRange = useMemo(() => {
    let min = Infinity;
    let max = -Infinity;
    filteredData.forEach(({ data }) => {
      data.forEach((point) => {
        if (point.price < min) min = point.price;
        if (point.price > max) max = point.price;
      });
    });
    return { min: Math.floor(min / 10) * 10, max: Math.ceil(max / 10) * 10 };
  }, [filteredData]);

  // Custom Tooltip for detailed info
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const point = payload[0]?.payload; // Access the actual data point
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <p>
            <strong>Date:</strong> {point?.timestamp || "N/A"}
          </p>
          <p>
            <strong>Price (USD):</strong> {point?.price || "N/A"}
          </p>
          <p>
            <strong>Plan Description:</strong> {point?.description || "N/A"}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {/* Multi-Select Dropdown */}
      <Select
        mode="multiple"
        style={{ width: "100%", marginBottom: "20px" }}
        placeholder="Select company-plans to display"
        options={options}
        onChange={(value) => setSelectedPlans(value)}
      />
      {/* Render Scatter Chart for selected company-plans */}
      <div style={{ width: "100%", height: "500px" }}>
        <ResponsiveContainer>
        <ScatterChart margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="timestamp" name="Date" />
  <YAxis dataKey="price" name="Price (USD)" />
  <Tooltip content={<CustomTooltip />} />
  <Legend />
  {filteredData.map(({ company, plan, data }, index) => (
    <Scatter
      key={`${company}-${plan}`}
      name={`${company} - ${plan}`}
      data={data}
      fill={chroma(companyColors[company]).brighten(index / 2).hex()}
    />
  ))}
</ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PricingChart;