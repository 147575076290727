import React, { useState } from "react";
import { Card, Row, Col, Select, Button, Typography, Input, Pagination, message } from "antd";

const { Option } = Select;
const { Title, Paragraph, Link } = Typography;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const AssociationsCart = ({ data,setRefreshAssociations }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [paginatedData, setPaginatedData] = useState(data.slice(0, 5));
  const [currentPage, setCurrentPage] = useState(1);
  const [editedData, setEditedData] = useState({});
  const [hoveredId, setHoveredId] = useState(null); // For managing hover state

  const predefinedTypes = ["integrations", "partners", "competitors", "customers"];

  // Handle filters
  const handleFilterChange = (filterType, value) => {
    const filtered = data.filter((item) => {
      if (filterType === "company" && value && item.company !== value) {
        return false;
      }
      if (
        filterType === "associated_company" &&
        value &&
        item.associated_company !== value
      ) {
        return false;
      }
      if (
        filterType === "association_type" &&
        value &&
        item.association_type !== value
      ) {
        return false;
      }
      return true;
    });

    setFilteredData(filtered);
    setPaginatedData(filtered.slice(0, 5));
    setCurrentPage(1);
  };

  // Handle pagination
  const handlePageChange = (page) => {
    const startIndex = (page - 1) * 5;
    const endIndex = startIndex + 5;
    setPaginatedData(filteredData.slice(startIndex, endIndex));
    setCurrentPage(page);
  };

  // Handle association type edit
  const handleEditType = (id, value) => {
    setEditedData((prev) => ({
      ...prev,
      [id]: { ...prev[id], association_type: value },
    }));
  };

  // Handle saving changes
  // Handle saving changes
const handleSaveChanges = async (id) => {
    if (!editedData[id]) return;
  
    try {
      const response = await fetch(`${BASE_URL}/api/updateAssociationType`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id, ...editedData[id] }),
      });
  
      if (!response.ok) throw new Error("Failed to update association type");
  
      // Update the frontend data directly
      setPaginatedData((prev) =>
        prev.map((item) =>
          item._id === id ? { ...item, ...editedData[id] } : item
        )
      );
  
      setFilteredData((prev) =>
        prev.map((item) =>
          item._id === id ? { ...item, ...editedData[id] } : item
        )
      );
  
      message.success("Association type updated successfully!");
      setEditedData((prev) => {
        const newData = { ...prev };
        delete newData[id];
        return newData;
      });
    } catch (error) {
      message.error("Failed to save changes. Please try again.");
    }
  };

  return (
    <div>
      {/* Filters */}
      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <Col span={8}>
          <Select
            placeholder="Filter by Company"
            style={{ width: "100%" }}
            onChange={(value) => handleFilterChange("company", value)}
            allowClear
          >
            {[...new Set(data.map((item) => item.company))].map((company) => (
              <Option key={company} value={company}>
                {company}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <Select
            placeholder="Filter by Associated Company"
            style={{ width: "100%" }}
            onChange={(value) => handleFilterChange("associated_company", value)}
            allowClear
          >
            {[...new Set(data.map((item) => item.associated_company))].map(
              (associatedCompany) => (
                <Option key={associatedCompany} value={associatedCompany}>
                  {associatedCompany}
                </Option>
              )
            )}
          </Select>
        </Col>
        <Col span={8}>
          <Select
            placeholder="Filter by Association Type"
            style={{ width: "100%" }}
            onChange={(value) => handleFilterChange("association_type", value)}
            allowClear
          >
            {[...new Set(data.map((item) => item.association_type))].map(
              (type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              )
            )}
          </Select>
        </Col>
      </Row>

      {/* Cards */}
      <Row gutter={[16, 16]}>
        {paginatedData.map((item, index) => (
          <Col span={24} key={index}>
            <Card
                bordered={false}
                style={{
                    background: index % 2 === 0 ? "#f7f8fa" : "#ffffff", // Alternating colors
                    border: "1px solid #e0e0e0", // Optional: professional border
                    borderRadius: "16px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
                    marginBottom: "20px",
                }}
                >
              <Row align="middle" justify="space-between">
                <Col>
                <Title level={4} style={{ marginBottom: "8px", color: "#212529" }}>
                {`${item.company} • ${item.associated_company}`}
                </Title>
                </Col>
                <Col
                  onMouseEnter={() => setHoveredId(item._id)}
                  onMouseLeave={() => setHoveredId(null)}
                >
                  {hoveredId === item._id ? (
                    <Select
                      style={{ width: "200px" }}
                      value={
                        editedData[item._id]?.association_type ||
                        item.association_type
                      }
                      onChange={(value) => handleEditType(item._id, value)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Input
                            placeholder="Enter custom type"
                            onPressEnter={(e) => {
                              handleEditType(item._id, e.target.value);
                              setHoveredId(null);
                            }}
                            style={{ margin: "8px" }}
                          />
                        </div>
                      )}
                    >
                      {predefinedTypes.map((type) => (
                        <Option key={type} value={type}>
                          {type}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <span style={{ cursor: "pointer", color: "#1890ff" }}>
                      {editedData[item._id]?.association_type ||
                        item.association_type}
                    </span>
                  )}
                </Col>
              </Row>

              <Paragraph style={{ color: "#495057", marginBottom: "16px" }}>
                {item.associated_company_website_summary}
                </Paragraph>
               <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Link href={item.associated_company_linkedin_url} target="_blank">
                    LinkedIn: {item.associated_company_linkedin_url}
                  </Link>
                </Col>
                <Col span={12}>
                  <Link href={item.associated_company_website_url} target="_blank">
                    Website: {item.associated_company_website_url}
                  </Link>
                </Col>
              </Row>

              {/* Save Button */}
              {editedData[item._id] && (
                <Button
                type="primary"
                style={{
                  marginTop: "20px",
                  background: "#007bff",
                  borderColor: "#007bff",
                }}
                onClick={() => handleSaveChanges(item._id)}
              >
                Save
              </Button>
              )}
            </Card>
          </Col>
        ))}
      </Row>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        pageSize={5}
        total={filteredData.length}
        onChange={handlePageChange}
        style={{ textAlign: "center", marginTop: "20px" }}
      />
    </div>
  );
};

export default AssociationsCart;